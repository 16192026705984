import React, { useState, useEffect, useContext } from 'react';
import AppContext from "../contexts/AppContext";
import { useNavigate } from 'react-router-dom';
import FloatingFooter from '../components/FloatingFooter'
import InputWithCounter from '../components/InputWithCounter'
import ConfirmModal from '../components/ConfirmModal';
import InformModal from '../components/InformModal';

const UserManagement = () => {
    const [users, setUsers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedUser, setSelectedUser] = useState(null);
    const [originalSelectedUserData, setOriginalSelecteddUserData] = useState(null);
    const { token, userInfo } = useContext(AppContext);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [hasChange, setHasChange] = useState(false);
    const [isPasswordResetModalOpen, setIsPasswordResetModalOpen] = useState(false);
    const [isSortedAsc, setIsSortedAsc] = useState(true);
    const navigate = useNavigate();

    const [infoModal, setInfoModal] = useState({
        isOpen: false,
        content: "",
        type: "inform", // "inform" or "error"
        onClose: null
    });

    useEffect(() => {
        
        if (selectedUser && originalSelectedUserData) {
            // 将对象转换为字符串以进行深度比较
            const isEqual = JSON.stringify(selectedUser) === JSON.stringify(originalSelectedUserData);
            setHasChange(!isEqual);  
        } else {
            setHasChange(false);
        }
    }, [selectedUser, originalSelectedUserData]);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/usersupport/getuserslist`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                });
                const data = await response.json();
                setUsers(data);
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };
        fetchUsers();
    }, [token]);

    useEffect(() => {
        let timer;
        
        if (infoModal.isOpen) {      
          timer = setTimeout(() => {
            // This triggers the same effect as user closing the modal
            if (infoModal.onClose) {
              infoModal.onClose();
            }
          }, 2000);
        }
        
        return () => {
          if (timer) {
            clearTimeout(timer);
          }
        };
      }, [infoModal.isOpen, infoModal.onClose, infoModal]);


    const handleSearch = (e) => setSearchTerm(e.target.value);

    const handleUserSelect = async (userId) => {
        try {
            const requestBody = { userId };
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/usersupport/getuserinfobyidforactivation`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(requestBody),
            });
            const data = await response.json();
            setSelectedUser(data);
            setOriginalSelecteddUserData(data);
        } catch (error) {
            console.error('Error fetching user details:', error);
        }
    };

    const createUser = async () => navigate('/usermanagement/initialnewuser');

    const createSpot = async () => {
        if (selectedUser) {
            navigate('/usermanagement/initialspotoradv/venue', { state: { userId: selectedUser.user.id, userEmail: selectedUser.user.email, accountName: selectedUser.user.AccountName, accountStatus: selectedUser.isAccountActived } });
        } else {
            alert("Please select a user first.");
        }
    };

    const createAdv = async () => {
        if (selectedUser) {
            navigate('/usermanagement/initialspotoradv/adv', { state: { userId: selectedUser.user.id, userEmail: selectedUser.user.email, accountName: selectedUser.user.AccountName, accountStatus: selectedUser.isAccountActived } });
        } else {
            alert("Please select a user first.");
        }
    };

    // 先过滤后排序
    const filteredUsers = users.filter(user =>
        user.AccountName && user.AccountName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const sortedUsers = [...filteredUsers].sort((a, b) => {
        if (isSortedAsc) {
            return a.AccountName.localeCompare(b.AccountName);
        } else {
            return b.AccountName.localeCompare(a.AccountName);
        }
    });

    const handleSelectedUserAccountEmailUpdate = async (field, value) => {
        setSelectedUser((prev) => ({
            ...prev,
            user: {
                ...prev.user,
                [field]: value
            },
        }));
    };

    const handleSave = async () => {
        const operationUserId = userInfo.user.id;
        if (!selectedUser || !originalSelectedUserData) return;
      
        const { user: currentUser } = selectedUser;
        const { user: originalUser } = originalSelectedUserData;
      
        // Gather success messages
        const successMessages = [];
      
        try {
          // 1) Check for email changes
          if (currentUser.email !== originalUser.email) {
            // Make the update call
            let response = await fetch(
              `${process.env.REACT_APP_API_BASE_URL}/usersupport/updateusercredentialbyadmin`,
              {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                  operationUserId,
                  userId: currentUser.id,
                  updateKey: 'email',
                  updateValue: currentUser.email,
                }),
              }
            );
      
            if (!response.ok) {
              const errorMsg = await response.text();
              throw new Error(`Error updating email: ${errorMsg}`);
            }
      
            // Send new email out
            response = await fetch(
              `${process.env.REACT_APP_API_BASE_URL}/usersupport/send-user-new-email-by-admin`,
              {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                  email: originalUser.email,
                  accountName: currentUser.AccountName,
                  newUserAccountEmail: currentUser.email
                }),
              }
            );
      
            if (!response.ok) {
              const errorMsg = await response.text();
              throw new Error(`Error sending new email notification: ${errorMsg}`);
            }
      
            // If all OK
            successMessages.push("Email has been updated successfully.");
          }
      
          // 2) Check for Account Name changes
          if (currentUser.AccountName !== originalUser.AccountName) {
            let response = await fetch(
              `${process.env.REACT_APP_API_BASE_URL}/usersupport/updateusercredentialbyadmin`,
              {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                  operationUserId,
                  userId: currentUser.id,
                  updateKey: 'AccountName',
                  updateValue: currentUser.AccountName,
                }),
              }
            );
      
            if (!response.ok) {
              const errorMsg = await response.text();
              throw new Error(`Error updating Account Name: ${errorMsg}`);
            }
      
            successMessages.push("Account Name has been updated successfully.");
          }
      
          // 3) Check for UserContact changes
          if (JSON.stringify(currentUser.UserContact) !== JSON.stringify(originalUser.UserContact)) {
            let response = await fetch(
              `${process.env.REACT_APP_API_BASE_URL}/usersupport/updateusercredentialbyadmin`,
              {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                  operationUserId,
                  userId: currentUser.id,
                  updateKey: 'UserContact',
                  updateValue: currentUser.UserContact,
                }),
              }
            );
      
            if (!response.ok) {
              const errorMsg = await response.text();
              throw new Error(`Error updating User Contact: ${errorMsg}`);
            }
      
            successMessages.push("User contact information has been updated successfully.");
          }
      
          // If we have at least one success message, show them
          if (successMessages.length > 0) {
            setInfoModal({
              isOpen: true,
              content: successMessages.join("\n"), // combine them or show them in separate lines
              type: "inform",
              onClose: () => {
                setInfoModal((prev) => ({ ...prev, isOpen: false }));
                window.location.reload();
              },
            });
          }
      
        } catch (error) {
          // If any of the fetch calls above fail, you'll end up here
          console.error("Error saving changes:", error);
          setInfoModal({
            isOpen: true,
            content: `An error occurred while saving changes:\n${error.message}`,
            type: "error",
            onClose: () => {
                setInfoModal((prev) => ({ ...prev, isOpen: false }));
                window.location.reload();
              },
          });
        }
      };

    const handlePasswordResetConfirm = async () => {
        if (!selectedUser || !originalSelectedUserData) return;
      
        try {
          const { user: currentUser } = selectedUser;
          const operationUserId = userInfo.user.id;
      
          // 1) Update user credential
          let response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/usersupport/updateusercredentialbyadmin`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
              },
              body: JSON.stringify({
                operationUserId,
                userId: currentUser.id,
                updateKey: 'password',
                updateValue: {}
              }),
            }
          );
      
          if (!response.ok) {
            const errorMsg = await response.text();
            throw new Error(`Error updating password: ${errorMsg}`);
          }
      
          // 2) Send reset email
          response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/usersupport/send-reset-password-by-admin`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
              },
              body: JSON.stringify({
                email: currentUser.email,
                accountName: currentUser.AccountName
              }),
            }
          );
      
          if (!response.ok) {
            const errorMsg = await response.text();
            throw new Error(`Error sending reset password email: ${errorMsg}`);
          }
      
          // If everything works
          setInfoModal({
            isOpen: true,
            content: "Password has been reset successfully.",
            type: "inform",
            onClose: () => {
                setInfoModal((prev) => ({ ...prev, isOpen: false }));
                window.location.reload();
              },
          });
      
        } catch (error) {
          console.error('Error resetting password:', error);
          setInfoModal({
            isOpen: true,
            content: `Error occurred while resetting password:\n${error.message}`,
            type: "error",
            onClose: () => {
                setInfoModal((prev) => ({ ...prev, isOpen: false }));
                window.location.reload();
              },
          });
        }
      
        // Finally close the password reset modal
        setIsPasswordResetModalOpen(false);
      };


    // 打开模态框
    const openModal = () => {
        setIsModalOpen(true);
    };

    // 关闭模态框
    const closeModal = () => {
        setIsModalOpen(false);
    };

    // 确认保存并执行 handleSave
    const confirmSave = async () => {
        await handleSave();
        closeModal(); // 保存后关闭模态框
        setHasChange(false);
    };


    // 切换排序
    const handleSortToggle = () => {
        setIsSortedAsc(!isSortedAsc);
    };

    return (
        <div className="pl-0 lg:pl-64 pt-20">
            <div className="p-4 bg-white shadow-md rounded-lg max-w-screen-2xl mx-auto mt-5 min-h-[76vh] max-h-auto">
                <header className="flex items-center justify-between pb-4 border-b mb-6">
                    <h1 className="text-xl font-bold text-gray-800">User Management</h1>
                    <button
                        className="bg-teal-500 text-white px-4 py-2 rounded-md hover:bg-teal-600 transition"
                        onClick={createUser}
                    >
                        New User
                    </button>
                </header>

                <div className="flex space-x-6">
                    {/* Left side: Search and User List */}
                    <div className="w-1/3">

                        <div className="flex items-center space-x-2 mb-4">
                            <input
                                type="text"
                                value={searchTerm}
                                onChange={handleSearch}
                                placeholder="Search user by account name"
                                className="flex-1 p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600"
                            />

                            <button
                                onClick={handleSortToggle}
                                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                            >
                                Sort {isSortedAsc ? 'Descending' : 'Ascending'}
                            </button>

                        </div>

                        <ul className="space-y-2 overflow-y-auto max-h-[55vh]">

                            {sortedUsers.map(user => (
                                <li
                                    key={user.UserId}
                                    className="p-2 bg-gray-50 rounded-md cursor-pointer hover:bg-gray-100 transition"
                                    onClick={() => handleUserSelect(user.UserId)}
                                >
                                    {user.AccountName}
                                </li>
                            ))}

                        </ul>
                    </div>

                    {/* Right side: User Details */}
                    <div className="flex-1">
                        {selectedUser ? (
                            <div className="space-y-6 overflow-y-auto max-h-[65vh]">
                                <h3 className="text-xl font-semibold">User Details</h3>
                                <div className="border-b pb-4 mb-6">

                                    <div className="flex items-center space-x-2">
                                        <strong>Account Name : </strong>
                                        <div className="w-80 relative -top-[13px]">
                                            <InputWithCounter
                                                type="AccountName"
                                                value={selectedUser.user.AccountName || ''}
                                                maxLength={64}
                                                onChange={(e) => handleSelectedUserAccountEmailUpdate('AccountName', e.target.value)}
                                                className="p-1 border border-gray-300 rounded"
                                            />
                                        </div>
                                    </div>

                                    <p>
                                        <strong>Active Status : </strong>{' '}
                                        <span
                                            className={
                                                selectedUser.isAccountActived
                                                    ? 'text-green-500 font-semibold'
                                                    : 'text-red-500 font-semibold'
                                            }
                                        >
                                            {selectedUser.isAccountActived ? 'Yes' : 'No'}
                                        </span>
                                    </p>

                                    <div className="flex items-center space-x-2">
                                        <strong>Account Email : </strong>
                                        <div className="w-80 relative -top-[13px]">
                                            <InputWithCounter
                                                type="email"
                                                value={selectedUser.user.email || ''}
                                                maxLength={64}
                                                onChange={(e) => handleSelectedUserAccountEmailUpdate('email', e.target.value)}
                                                className="p-1 border border-gray-300 rounded"
                                            />
                                        </div>
                                    </div>

                                    <div className="flex items-center space-x-2">
                                        <strong>Enforce Password Reset : </strong>
                                        <button
                                            className="bg-red-500 text-white px-2 py-1 rounded-md hover:bg-red-600"
                                            onClick={() => setIsPasswordResetModalOpen(true)}
                                        >
                                            Change Password
                                        </button>

                                    </div>



                                </div>

                                <ConfirmModal
                                    isOpen={isPasswordResetModalOpen}
                                    onClose={() => setIsPasswordResetModalOpen(false)}
                                    onConfirm={handlePasswordResetConfirm}
                                    header="Confirm Password Reset"
                                    content="Are you sure you want to reset the account password?"
                                />

                                <InformModal
                                    isOpen={infoModal.isOpen}
                                    onClose={infoModal.onClose}
                                    content={infoModal.content}
                                    type={infoModal.type}
                                />

                                <div className="flex space-x-8 mt-6">

                                    {/* Venues Part */}
                                    <div className="w-1/2 space-y-4 border-r mt-2">

                                        <div className="flex items-center space-x-8">
                                            <h3 className="text-xl font-semibold ">Venues</h3>
                                            <button
                                                className="inline-flex items-center justify-center bg-teal-500 text-white w-48 py-1 px-4 rounded-md hover:bg-teal-600 transition"
                                                onClick={createSpot}
                                            >
                                                New Venue
                                            </button>

                                        </div>

                                        <ul className="mt-2 space-y-2">
                                            {selectedUser.roleInfoIdCollection.spots.map(spot => (
                                          
                                                <li
                                                    key={spot.id}
                                                    className="ml-4 cursor-pointer hover:text-blue-500 transition duration-300"
                                                    onClick={() => {
                                                        navigate(`/usermanagement/editvenue/${spot.id}?spotName=${spot.name}`);
                                                    }}
                                                >
                                                    {spot.name}
                                                </li>

                                            ))}
                                        </ul>

                                    </div>

                                    {/* Advs Part */}
                                    <div className="w-1/2 space-y-4 mt-2">

                                        <div className="flex items-center space-x-8">
                                            <h3 className="text-xl font-semibold">Advertisments</h3>
                                            <button
                                                className="inline-flex items-center justify-center bg-teal-500 text-white w-48 py-1 px-4 rounded-md hover:bg-teal-600 transition"
                                                onClick={createAdv}
                                            >
                                                New Advertisment
                                            </button>

                                        </div>

                                        <ul className="mt-2 space-y-2">
                                            {selectedUser.roleInfoIdCollection.advs.map(adv => (
                                                <li
                                                    key={adv.id}
                                                    className="ml-4 cursor-pointer hover:text-blue-500 transition duration-300"
                                                    onClick={() => navigate(`/usermanagement/editadv/${adv.id}`)}
                                                >
                                                    {adv.name}
                                                </li>
                                            ))}
                                        </ul>

                                    </div>

                                </div>

                            </div>
                        ) : (
                            <p className="text-gray-500">Select a user to view their details</p>
                        )}
                    </div>
                </div>
            </div>
            {/* Save Button */}
            <div className="text-right">

                <FloatingFooter hasChanges={hasChange} onSave={openModal} />

                {/* Confirm Modal */}
                <ConfirmModal
                    isOpen={isModalOpen}
                    onClose={closeModal}
                    onConfirm={confirmSave}
                    header="Confirm Save"
                    content="Are you sure you want to save the changes?" />
            </div>
        </div>
    );
};

export default UserManagement;
