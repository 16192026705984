import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation,Navigate  } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import Login from './pages/Login';
import Content from './pages/Content.js';
import Detail from './pages/Detail.js';
import Article from './pages/Article.js';
import ArticleDetail from './pages/ArticleDetail.js';
import ForgotPassword from './pages/ForgetPassword';
import ArticleSelector from './pages/ArticleSelector.js';
import Activation from './pages/Activation.js';
import { AppProvider } from './contexts/AppContext';
import RequireAuth from './components/RequireAuth';
import ResetPassword from './pages/ResetPwd';
import SideNavigationBar from './components/SideNavigationBar.js';
import UserManagement from './pages/UserManagement.js';
import InitialNewSpotOrAdv from './pages/InitialNewSpotOrAdv.js';
import InitialNewUser from './pages/InitialNewUser.js';
import Profile from './pages/Profile.js';
import Analytics from './pages/Analytics.js';
import Dashboard from './pages/Dashboard.js';
import RoleBinding from './pages/RoleBinding.js';
import EditAdv from './pages/EditAdv.js';
import EditVenue from './pages/EditVenue.js';


const MainApp = () => {
  let location = useLocation();  // 获取当前路径，用于条件渲染

  return (
    <AppProvider>
      <div className="flex flex-col h-screen">
        {/* 根据路径条件显示 Navbar 或 SideNavigationBar */}
        {(location.pathname !== '/login' && location.pathname !== '/forgetpwd' && !location.pathname.includes('/resetpwd')  && !location.pathname.includes( '/activation') ) ? <SideNavigationBar /> : null}
        <div className="flex-grow flex flex-col">
          {/* Navbar on top */}

          <div className='flex-grow overflow-auto bg-gray-100'>
            {(location.pathname !== '/login' && location.pathname !== '/forgetpwd' && !location.pathname.includes('/resetpwd') && !location.pathname.includes( '/activation')) && <Navbar />}
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/forgetpwd" element={<ForgotPassword />} />
              <Route path="/resetpwd" element={<ResetPassword />} />
              <Route path="/activation" element={<Activation/>}/>
              <Route path="*" element={<Navigate to="/login" replace />} />
              <Route path="/" element={<RequireAuth><Dashboard/></RequireAuth>}/>
              <Route path="/content" element={<RequireAuth><Content /></RequireAuth>}/>
              <Route path="/landingpage" element={<RequireAuth><Home/></RequireAuth>} />
              <Route path="/landingpage/:activeTab" element={<RequireAuth><Home/></RequireAuth>} />
              <Route path="/detail/:id" element={<RequireAuth><Detail/></RequireAuth>} />
              <Route path="/detail/:id/add" element={<RequireAuth><Detail/></RequireAuth>} />
              <Route path="/detail/:id/add/list" element={<RequireAuth><Detail/></RequireAuth>} />
              <Route path="/detail/:id/add/entry" element={<RequireAuth><Detail/></RequireAuth>} />
              <Route path="/article" element={<RequireAuth><Article/></RequireAuth>} />
              <Route path="/article/:activeTab" element={<RequireAuth><Article/></RequireAuth>} />
              <Route path="/articleselector" element={<RequireAuth><ArticleSelector/></RequireAuth>} />
              <Route path="/articledetail/:id" element={<RequireAuth><ArticleDetail/></RequireAuth>} />
              <Route path="/usermanagement" element={<RequireAuth><UserManagement/></RequireAuth>} />
              <Route path="/usermanagement/initialnewuser" element={<RequireAuth><InitialNewUser/></RequireAuth>} />
              <Route path="/usermanagement/initialspotoradv/:activeTab" element={<RequireAuth><InitialNewSpotOrAdv/></RequireAuth>} />
              <Route path="/usermanagement/editadv/:advId" element={<RequireAuth><EditAdv/></RequireAuth>} />
              <Route path="/usermanagement/editvenue/:spotId" element={<RequireAuth><EditVenue/></RequireAuth>} />
              <Route path="/profile" element={<RequireAuth><Profile/></RequireAuth>} />
              <Route path="/profile/:activeTab" element={<RequireAuth><Profile/></RequireAuth>} />
              <Route path="/analytics" element={<RequireAuth><Analytics/></RequireAuth>} />
              <Route path="/rolebinding" element={<RequireAuth><RoleBinding/></RequireAuth>} />
              
            </Routes>
          </div>

        </div>

      </div>
    </AppProvider>
  );
};

const App = () => {
  return (
    <Router>
      <MainApp />
    </Router>
  );
};

export default App;