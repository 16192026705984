import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import AppContext from '../contexts/AppContext';
import ConfirmModal from '../components/ConfirmModal';
import InformModal from '../components/InformModal';

const EditAdv = () => {
    const { advId } = useParams();
    const { token, userInfo } = useContext(AppContext);
    const [advData, setAdvData] = useState({
        name: '',
        spotIds: [],
    });
    const [spots, setSpots] = useState([]);
    const [sortOrder, setSortOrder] = useState('default'); // default, asc, desc, selected
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [informModalOpen, setInformModalOpen] = useState(false);

    useEffect(() => {
        if (userInfo && userInfo.user.email) {
            const requestBody = {
                email: userInfo.user.email,
                advId: advId
            };

            const fetchAdvDetails = async () => {
                try {
                    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/adv/viewadvpairedspot`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        },
                        body: JSON.stringify(requestBody)
                    });
                    const data = await response.json();
                    setAdvData((prevData) => ({
                        ...prevData,
                        name: data.name || 'Unnamed Adv', // Use the adv name from API
                        spotIds: data.pairedSpots.map(spot => spot.id) || [],
                    }));
                } catch (error) {
                    console.error('Error fetching advertisement details:', error);
                }
            };

            const fetchSpots = async () => {
                try {
                    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/spot/getallspotidandname`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        },
                    });
                    const data = await response.json();
                    setSpots(data);
                } catch (error) {
                    console.error('Error fetching spots:', error);
                }
            };

            fetchAdvDetails();
            fetchSpots();
        }
    }, [advId, token, userInfo]);

    const handleSpotCheck = (spotId) => {
        setAdvData((prevData) => {
            const spotIds = prevData.spotIds.includes(spotId)
                ? prevData.spotIds.filter(id => id !== spotId)
                : [...prevData.spotIds, spotId];
            return {
                ...prevData,
                spotIds,
            };
        });
    };

    const handleNameChange = (event) => {
        const { value } = event.target;
        setAdvData((prevData) => ({
            ...prevData,
            name: value,
        }));
    };

    const sortedSpots = () => {
        if (sortOrder === 'asc') {
            return [...spots].sort((a, b) => a.name.localeCompare(b.name));
        } else if (sortOrder === 'desc') {
            return [...spots].sort((a, b) => b.name.localeCompare(a.name));
        } else if (sortOrder === 'selected') {
            return [...spots].sort((a, b) => advData.spotIds.includes(b.id) - advData.spotIds.includes(a.id));
        }
        return spots;
    };

    const handleSortChange = (event) => {
        setSortOrder(event.target.value);
    };

    const handleSubmit = async () => {
        setConfirmModalOpen(true);
    };

    const confirmSubmit = async () => {
        try {
            const requestBody = {
                email: userInfo.user.email,
                advId,
                spotIds: advData.spotIds,
                advName: advData.name,
            };

            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/adv/editadvwithspotids`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(requestBody),
            });

            if (response.status === 200) {
                setInformModalOpen(true);
            } else {
                const errorData = await response.json();
                console.error('Error updating advertisement:', errorData);
            }
        } catch (error) {
            console.error('Error submitting form:', error);
        } finally {
            setConfirmModalOpen(false);
        }
    };

    return (
        <div className="pl-0 lg:pl-64 pt-20">
            <div className="p-4 bg-white shadow-md rounded-lg max-w-[1500px] mx-auto mt-5 min-h-[650px] mb-10">
                <h1 className="text-2xl font-bold mb-6">Edit Advertisement</h1>

                <div className="mx-4 mb-6">
                    <label htmlFor="advName" className="block text-lg font-medium text-gray-700 mb-2">Advertisement Name</label>
                    <input
                        id="advName"
                        type="text"
                        value={advData.name}
                        onChange={handleNameChange}
                        className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    />
                </div>

                <div className="flex justify-between items-center mx-4 mb-4">
                    <h1 className="font-semibold text-lg">Select Venues</h1>
                    <div>
                        <select
                            value={sortOrder}
                            onChange={handleSortChange}
                            className="px-4 py-2 bg-gray-200 rounded-md"
                        >
                            <option value="default">Default</option>
                            <option value="asc">Sort A-Z</option>
                            <option value="desc">Sort Z-A</option>
                            <option value="selected">Selected First</option>
                        </select>
                    </div>
                </div>

                <div className="p-4 border border-gray-500 rounded-lg shadow-sm h-[35vh] overflow-y-auto">
                    {sortedSpots().map(spot => (
                        <label key={spot.id} className="flex items-center space-x-2 cursor-pointer">
                            <input
                                type="checkbox"
                                checked={advData.spotIds.includes(spot.id)}
                                onChange={() => handleSpotCheck(spot.id)}
                                className="form-checkbox text-blue-500 h-5 w-5 cursor-pointer"
                            />
                            <span className="text-gray-700">{spot.name}</span>
                        </label>
                    ))}
                </div>

                <div className="flex justify-end mr-10 mt-6">
                    <button
                        onClick={handleSubmit}
                        className="bg-green-500 text-white px-10 py-2 rounded-md"
                    >
                        Save Changes
                    </button>
                </div>
            </div>

            <ConfirmModal
                isOpen={confirmModalOpen}
                onClose={() => setConfirmModalOpen(false)}
                onConfirm={confirmSubmit}
                header="Confirm Changes"
                content="Are you sure you want to save the changes?"
            />

            <InformModal
                isOpen={informModalOpen}
                onClose={() => setInformModalOpen(false)}
                content="Advertisement updated successfully!"
                type="inform"
            />
        </div>
    );
};

export default EditAdv;
