import React, { useState, useEffect, useContext } from "react";
import AppContext from "../contexts/AppContext";
import { Line } from "react-chartjs-2";
import "react-datepicker/dist/react-datepicker.css";
import "chart.js/auto";

const SpotTrafficDashboard = () => {
    const { selectedRole, token } = useContext(AppContext);
    const [trendData, setTrendData] = useState([]);

    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1); 
    const sevenDaysAgo = new Date(yesterday);
    sevenDaysAgo.setDate(yesterday.getDate() - 7); 
    const [startDate, setStartDate] = useState(sevenDaysAgo); 
    const [endDate, setEndDate] = useState(yesterday); 
    const [loading, setLoading] = useState(false);
    const [timeRange, setTimeRange] = useState("7"); // 默认为过去 7 天

    const [maxTicks, setMaxTicks] = useState(window.innerWidth < 640 ? 4 : 20);

    useEffect(() => {
        const handleResize = () => {
            setMaxTicks(window.innerWidth < 640 ? 4 : 20);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const handleTimeRangeChange = (e) => {
        const range = e.target.value;
        setTimeRange(range);

        const newEndDate = new Date(yesterday);
        let newStartDate = new Date(newEndDate);

        if (range === "7") {
            newStartDate.setDate(newEndDate.getDate() - 7);
        } else if (range === "15") {
            newStartDate.setDate(newEndDate.getDate() - 15);
        } else if (range === "30") {
            newStartDate.setMonth(newEndDate.getMonth() - 1);
        }else if (range === "90") {
            newStartDate.setMonth(newEndDate.getMonth() - 3);
        }
        setStartDate(newStartDate);
        setEndDate(newEndDate);
    };


    useEffect(() => {
        const fetchTrafficData = async () => {
            try {
                setLoading(true);

                const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                if (!selectedRole || !userTimeZone) {
                    throw new Error("Missing spotId or userTimeZone");
                }

                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/analysis/getspottrafficandregion`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        spotId: selectedRole.id,
                        startDate,
                        endDate,
                        userTimeZone,
                    }),
                });

                const data = await response.json();
    

                setTrendData(data.trend || []);
            } catch (error) {
                console.error("Failed to fetch data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchTrafficData();
    }, [startDate, endDate, selectedRole, token]); // 确保依赖项正确设置

    const renderTrendChart = () => {
        const labels = trendData.map((item) => item.date);
        const durations = trendData.map((item) => item.totalDuration);

        const data = {
            labels,
            datasets: [
                {
                    label: "Duration by Date (min)",
                    data: durations,
                    borderColor: "rgba(75,192,192,1)",
                    backgroundColor: "rgba(75,192,192,0.2)",
                },
            ],
        };

        const options = {
            responsive: true,
            maintainAspectRatio: false, // 宽高比不固定
            plugins: {
                legend: {
                    onClick: () => {}, // 禁用点击隐藏数据功能
                },
            },
            scales: {
                x: {
                    title: {
                        display: true, // 显示标题
                        text: 'Time (Date)', // x轴单位
                        font: {
                            size: 14, // 设置字体大小
                            weight: 'bold', // 字体加粗
                        },
                        color: '#4B5563', // 设置标题颜色
                    },
                    ticks: {
                        maxTicksLimit: maxTicks, // 限制最多显示的刻度
                    },
                },
                y: {
                    title: {
                        display: true, // 显示标题
                        text: 'Minutes', // y轴单位
                        font: {
                            size: 14, // 设置字体大小
                            weight: 'bold', // 字体加粗
                        },
                        color: '#4B5563', // 设置标题颜色
                    },
                },
            },
        };



        return (
            <div className="w-full h-[35vh] md:h-[35vh]">
                {loading ? (
                    <p className="text-lg font-semibold text-gray-500">Loading...</p>
                ) : (
                    <Line data={data} options={options} />
                )}
            </div>
        );
    };
    return (
        <div className="relative flex flex-col gap-4 p-6 bg-gray-50 rounded-lg shadow-lg overflow-visible">

            <div className="flex gap-4 items-center">

                 {/* 时间范围下拉菜单 */}
                 <label className="text-gray-600 font-medium">
                    Time Range:
                    <select
                        value={timeRange}
                        onChange={handleTimeRangeChange}
                        className="ml-2 p-2 border rounded"
                    >
                        <option value="7">Past 7 Days</option>
                        <option value="15">Past 15 Days</option>
                        <option value="30">Past 1 Month</option>
                        <option value="90">Past 3 Month</option>
                    </select>
                </label>
            </div>

            <div className="h-auto w-full mb-4">
                <h3 className="text-l font-semibold mb-2 text-center flex items-center justify-center">
                    Browsing Time Analysis
                    <span className="ml-2 relative group cursor-pointer">
                        <span className="inline-flex items-center justify-center w-6 h-6 rounded-full bg-gray-300 text-gray-700 text-sm font-bold">
                            ?
                        </span>
                        {/* Tooltip */}
                        <div className="absolute hidden group-hover:block bg-white text-gray-700 text-sm p-2 rounded shadow-md whitespace-normal w-[400px] -top-10 left-3/4 transform translate-x-[10%] text-justify">
                            This chart shows the total period of visits to your web page per day in minutes
                        </div>
                    </span>
                </h3>

                <div className="h-auto w-full">
                    {trendData.length > 0 ? renderTrendChart() : 
                    <p className="text-lg font-semibold text-gray-500 text-center">
                        No trend data available for the selected time range.
                    </p>}
                </div>
            </div>
        </div>
    );
};

export default SpotTrafficDashboard;