import React, { useEffect, useState, useContext } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { FaArrowLeft } from "react-icons/fa";
import AppContext from '../contexts/AppContext';
import LeafNode from '../components/LeafNode';
import NonLeafNode from '../components/NonLeafNode';
import InformModal from '../components/InformModal';
import ConfirmModal from '../components/ConfirmModal';
import FloatingFooter from '../components/FloatingFooter';

const Detail = () => {
  const { id } = useParams();
  const location = useLocation(); // 获取当前 URL 的路径
  const navigate = useNavigate();
  const { token, content, uploadImage, userInfo, selectedRole } = useContext(AppContext);
  const [detail, setDetail] = useState(null);
  const [initialDetail, setInitialDetail] = useState(null); // 纯用来判定是否有改动
  const isNew = location.pathname.includes('/add') ? (location.pathname.includes('/add/list') ? "list" : "entry") : "old";//糟糕的三元嵌套 isNew 修改为 list entry old三种状态
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isCheckModalOpen, setIsCheckModalOpen] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    if (detail && initialDetail && JSON.stringify(detail) !== JSON.stringify(initialDetail)) {
      setHasChanges(true);
    }
  }, [detail, initialDetail])

  useEffect(() => {
    // if (!isNew) {
    if (isNew === 'old') {
      // For "old" mode, utalise its origin detail
      const findNodeById = (id, node) => {
        const queue = [node];
        while (queue.length > 0) {
          const currentNode = queue.shift();
          if (currentNode.id === id) return currentNode;
          if (currentNode.attributes) {
            queue.push(...currentNode.attributes);
          }
        }
        return null;
      };

      if (content) {
        const node = findNodeById(id, content);
        setDetail(node);
        setInitialDetail(node);
      }
    } else {
      // For "new" mode, initialize a blank detail, seperate list or entry
      if (isNew === 'entry') {
        // entry initial set
        setDetail({
          name: '',
          description: '',
          isLeaf: true,  // Or false, depending on the type of content being created
          layoutStyle: 'None',
          content_parent_id: id,
          mainText: '',
          carouselImagePublic: [],
          carouselImagePrivate: [],
          geoLocation: {}
        });
        setInitialDetail({
          name: '',
          description: '',
          isLeaf: true,  // Or false, depending on the type of content being created
          layoutStyle: 'None',
          content_parent_id: id,
          mainText: '',
          carouselImagePublic: [],
          carouselImagePrivate: [],
          geoLocation: {}
        });
      } else {
        // list initial set
        setDetail({
          name: '',
          description: '',
          isLeaf: false,  // Or false, depending on the type of content being created
          layoutStyle: 'overlay',
          content_parent_id: id,
        });
        setInitialDetail({
          name: '',
          description: '',
          isLeaf: false,  // Or false, depending on the type of content being created
          layoutStyle: 'overlay',
          content_parent_id: id,
        });

      }
    }
  }, [id, content, isNew]);

  if (!detail) return <div>Loading...</div>;

  const handleInputChange = (e, arrayName, index) => {
    if (arrayName) {
      const value = e ? e.target.value : '';
      setDetail((prevDetail) => ({
        ...prevDetail,
        [arrayName]: prevDetail[arrayName].map((item, i) => (i === index ? value : item)),
      }));
    } else {
      const { name, value } = e.target;
      setDetail((prevDetail) => ({
        ...prevDetail,
        [name]: value,
      }));
    }
  };

  const handleImageChange = async (e, width, height) => {
    const file = e.target.files[0];
    const imagePublicAddress = await uploadImage(file, width, height);
    const name = e.target.name;
    setDetail((prevDetail) => ({
      ...prevDetail,
      [name]: imagePublicAddress,
    }));
  };

  const handleImageDelete = (name) => {
    setDetail((prevDetail) => ({
      ...prevDetail,
      [name]: '',
    }));
  };

  const saveContent = async () => {
    try {
      const apiUrl = isNew !== "old"
        ? `${process.env.REACT_APP_API_BASE_URL}/contentupdate/add`
        : `${process.env.REACT_APP_API_BASE_URL}/contentupdate/edit`;

      const requestBody = isNew !== "old"
        ? {
          email: userInfo.user.email,
          content_parent_id: detail.content_parent_id,
          isLeaf: detail.isLeaf,
          layoutStyle: detail.layoutStyle,
          name: detail.name,
          description: detail.description,
          bannerImage: detail.bannerImage,
          iconImage: detail.iconImage,
          carouselImages: detail.carouselImages,
          mainText: detail.mainText,
          contact: detail.contact,
          geoLocation: detail.geoLocation,
          searchTags: detail.searchTags
        }
        : {
          email: userInfo.user.email,
          content_id: detail.id,
          isLeaf: detail.isLeaf,
          layoutStyle: detail.layoutStyle,
          name: detail.name,
          description: detail.description,
          bannerImage: detail.bannerImage,
          iconImage: detail.iconImage,
          carouselImages: detail.carouselImages,
          mainText: detail.mainText,
          contact: detail.contact,
          geoLocation: detail.geoLocation,
          searchTags: detail.searchTags

        };

      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        const data = await response.json();
        setIsModalOpen(true);
        return data.fileUrl;
      } else {
        setIsErrorModalOpen(true);
        throw new Error('Failed to save content');
      }
    } catch (error) {
      console.error('Error saving content:', error);
    }
  };

  return (
    <div className="w-full max-w-7xl mx-auto lg:pl-64 pl-0  rounded-lg pt-20 pb-16">
      <div className="flex items-center space-x-2">
        <FaArrowLeft className={`h-6 w-6 text-gray-700 cursor-pointer ${selectedRole.type === 'adv' ? 'hidden' : ''}`} onClick={() => navigate(-1)} />
        <div className="text-3xl text-blue-600 font-semibold hidden md:block">{isNew !== "old" ? 'Add Content' : detail.isLeaf ? 'Directory Entry' : 'Directory List'}</div>
      </div>
      <div className="m-4 bg-white p-4 shadow-md rounded-lg">
        {detail.isLeaf ? (
          <LeafNode
            detail={detail}
            saveContent={saveContent}
            handleImageChange={handleImageChange}
            handleInputChange={handleInputChange}
            handleImageDelete={handleImageDelete}
            setDetail={setDetail}
            canSwitch={selectedRole.type !== 'adv'}
          />
        ) : (
          <NonLeafNode
            detail={detail}
            saveContent={saveContent}
            handleImageChange={handleImageChange}
            handleInputChange={handleInputChange}
            handleImageDelete={handleImageDelete}
            setDetail={setDetail}
          />
        )}
      </div>
      <FloatingFooter hasChanges={hasChanges} onSave={() => { setIsCheckModalOpen(true) }} />
      <InformModal
        isOpen={isModalOpen}
        onClose={() => { setIsModalOpen(false); navigate(-1); }}
        content={isNew ? "Successfully Added!" : "Successfully Edited!"}
        type='inform'
      />
      <InformModal
        isOpen={isErrorModalOpen}
        onClose={() => { setIsErrorModalOpen(false); }}
        content="Oops! Something went wrong, check again"
        type='alert'
      />
      <ConfirmModal
        isOpen={isCheckModalOpen}
        onClose={() => { setIsCheckModalOpen(false) }}
        onConfirm={() => { saveContent(); setIsCheckModalOpen(false); }}
        header="Confirm Save"
        content="Are you sure you want to save the changes?" />
    </div>
  );
};

export default Detail;
