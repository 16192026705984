import React, { useEffect, useState, useContext } from "react";
import AppContext from "../contexts/AppContext";
import { Line } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, ArcElement, ChartDataLabels);

const AdvLogDashboard = () => {
    const { selectedRole, token } = useContext(AppContext);
    const [advLogData, setAdvLogData] = useState([]);
    const [selectedSpotId, setSelectedSpotId] = useState("all");
    const [loading, setLoading] = useState(false);

    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1); // 昨天
    const sevenDaysAgo = new Date(yesterday);
    sevenDaysAgo.setDate(yesterday.getDate() - 7); // 七天前
    const [startDate, setStartDate] = useState(sevenDaysAgo); // 默认设置为七天前
    const [endDate, setEndDate] = useState(yesterday); // 默认设置为昨天
    const [timeRange, setTimeRange] = useState("7"); // 默认为过去 7 天

    const [maxTicks, setMaxTicks] = useState(window.innerWidth < 640 ? 4 : 20);

    const formatDate = (date) => date.toISOString().split("T")[0];

    useEffect(() => {
        const handleResize = () => {
            setMaxTicks(window.innerWidth < 640 ? 4 : 20);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const handleTimeRangeChange = (e) => {
        const range = e.target.value;
        setTimeRange(range);

        const newEndDate = new Date(yesterday);
        let newStartDate = new Date(newEndDate);

        if (range === "7") {
            newStartDate.setDate(newEndDate.getDate() - 7);
        } else if (range === "15") {
            newStartDate.setDate(newEndDate.getDate() - 15);
        } else if (range === "30") {
            newStartDate.setMonth(newEndDate.getMonth() - 1);
        } else if (range === "90") {
            newStartDate.setMonth(newEndDate.getMonth() - 3);
        }
        setStartDate(newStartDate);
        setEndDate(newEndDate);
    };

    useEffect(() => {

        const fetchAdvLogData = async () => {
            try {
                setLoading(true);
                const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/analysis/getadvlogsummary`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        advId: selectedRole.id,
                        startDate,
                        endDate,
                        userTimeZone,
                    }),
                });

                const data = await response.json();
                setAdvLogData(data);
            } catch (error) {
                console.error("Failed to fetch data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchAdvLogData();

    }, [endDate, selectedRole, startDate, token]);

    const handleSpotChange = (e) => {
        setSelectedSpotId(e.target.value);
    };

    const uniqueSpots = Array.from(
        advLogData.flatMap((entry) =>
            entry.spotDetails.map((spot) => ({
                spotId: spot.spotId,
                spotName: spot.spotName,
            }))
        ).reduce((map, spot) => map.set(spot.spotId, spot.spotName), new Map())
    );

    const calculateTotals = () => {
        const dateRange = generateDateRange(startDate, endDate);

        return dateRange.map((date) => {
            const entry = advLogData.find((e) => e.date === date);
            return {
                date,
                totalRecords: entry
                    ? entry.spotDetails.reduce((sum, spot) => sum + spot.totalRecords, 0)
                    : 0,
            };
        });
    };

    const generateDateRange = (start, end) => {
        const startDate = new Date(start);
        const endDate = new Date(end);
        const dateList = [];

        while (startDate <= endDate) {
            dateList.push(formatDate(startDate));
            startDate.setDate(startDate.getDate() + 1);
        }

        return dateList;
    };

    const calculateSpotTotals = () => {
        const dateRange = generateDateRange(startDate, endDate); // 生成完整的日期范围

        if (selectedSpotId === "all") {
            const hotelData = {};

            advLogData.forEach((entry) => {
                entry.spotDetails.forEach((spot) => {
                    if (!hotelData[spot.spotId]) {
                        hotelData[spot.spotId] = {
                            name: spot.spotName,
                            data: [],
                        };
                    }
                    hotelData[spot.spotId].data.push({
                        date: entry.date,
                        totalRecords: spot.totalRecords,
                    });
                });
            });

            // **填充缺失日期**
            return Object.entries(hotelData).map(([spotId, details]) => ({
                spotId,
                spotName: details.name,
                data: dateRange.map((date) => {
                    const record = details.data.find((d) => d.date === date);
                    return {
                        date,
                        totalRecords: record ? record.totalRecords : 0, // 如果没有数据，填充为 0
                    };
                }),
            }));
        }

        // 当选择具体 Spot 时的逻辑
        return dateRange.map((date) => {
            const entry = advLogData.find((e) => e.date === date);
            const spotDetails = entry?.spotDetails.find((spot) => spot.spotId === selectedSpotId);
            return {
                date,
                totalRecords: spotDetails ? spotDetails.totalRecords : 0, // 填充 0
            };
        });
    };

    const totalRecordsLineData = {
        labels: calculateTotals().map((item) => item.date),
        datasets: [
            {
                label: "Total Records",
                data: calculateTotals().map((item) => item.totalRecords),
                borderColor: "rgba(75,192,192,1)",
                backgroundColor: "rgba(75,192,192,0.2)",
            },
        ],
    };
    const selectedSpotName = uniqueSpots.find(([id]) => id === selectedSpotId)?.[1] || "All Spots";

    const filteredSpotTotalsData = {
        labels: generateDateRange(startDate, endDate), // **使用完整的日期范围**
        datasets:
            selectedSpotId === "all"
                ? calculateSpotTotals().map((spot) => ({
                    label: spot.spotName, // 每个 Spot 的名称
                    data: spot.data.map((entry) => entry.totalRecords), // **确保使用填充后的数据**
                    borderColor: `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(
                        Math.random() * 255
                    )}, 1)`, // 随机颜色
                    backgroundColor: "rgba(0,0,0,0)",
                }))
                : [
                    {
                        label: `Total Records (${selectedSpotName})`,
                        data: calculateSpotTotals().map((item) => item.totalRecords),
                        borderColor: "rgba(255,99,132,1)",
                        backgroundColor: "rgba(255,99,132,0.2)",
                    },
                ],
    };

    const optionsForLineChart = {
        responsive: true,
        maintainAspectRatio: false, // 确保宽度根据容器调整
        plugins: {
            datalabels: {
                display: false, // 禁用折线图上的数据标签
            },
            legend: {
                onClick: () => { }, // 禁用点击隐藏数据功能
            }
        },
        scales: {
            x: {
                title: {
                    display: true, // 显示标题
                    text: 'Time (Date)', // x轴单位
                    font: {
                        size: 14, // 设置字体大小
                        weight: 'bold', // 字体加粗
                    },
                    color: '#4B5563', // 设置标题颜色
                },
                ticks: {
                    maxTicksLimit: maxTicks, // 限制最多显示的刻度
                },
            },
            y: {
                title: {
                    display: true, // 显示标题
                    text: 'Click counts', // y轴单位
                    font: {
                        size: 14, // 设置字体大小
                        weight: 'bold', // 字体加粗
                    },
                    color: '#4B5563', // 设置标题颜色
                },
            },
        }






    };

    return (
        <div className=" p-6 bg-gray-50 rounded-lg shadow-lg">
            <div className="mb-4 flex justify-between items-center">
                <div className="flex gap-4 items-center">

                    {/* 时间范围下拉菜单 */}
                    <label className="text-gray-600 font-medium">
                        Time Range:
                        <select
                            value={timeRange}
                            onChange={handleTimeRangeChange}
                            className="ml-2 p-2 border rounded"
                        >
                            <option value="7">Past 7 Days</option>
                            <option value="15">Past 15 Days</option>
                            <option value="30">Past 1 Month</option>
                            <option value="90">Past 3 Month</option>
                        </select>
                    </label>
                </div>
            </div>

            <div className="h-[300px] mb-4">
                <h3 className="text-center font-semibold">Total Advertisement Clicks By Date
                    <span className="ml-2 relative group cursor-pointer">
                        <span className="inline-flex items-center justify-center w-6 h-6 rounded-full bg-gray-300 text-gray-700 text-sm font-bold">
                            ?
                        </span>
                        {/* Tooltip */}
                        <div className="absolute hidden group-hover:block bg-white text-gray-700 text-sm p-2 rounded shadow-md whitespace-normal w-[400px] -top-10 left-3/4 transform translate-x-[10%] text-justify">
                            This chart shows the total number of clicks on your advertisement across all the venues.
                        </div>
                    </span>
                </h3>
                <Line data={totalRecordsLineData} options={optionsForLineChart} />
            </div>

            <div className="flex gap-4 items-center mt-10 mb-10">
                <label className="text-lg font-medium">
                    Select Spot:
                    <select
                        value={selectedSpotId}
                        onChange={handleSpotChange}
                        className="mt-2 p-2 border rounded"
                    >
                        <option value="all">All Spots</option>
                        {uniqueSpots.map(([spotId, spotName]) => (
                            <option key={spotId} value={spotId}>
                                {spotName}
                            </option>
                        ))}
                    </select>
                </label>
            </div>

            <div className="h-[300px] mb-4">
                <h3 className="text-center font-semibold">Daily Click Trends by Venue
                    <span className="ml-2 relative group cursor-pointer">
                        <span className="inline-flex items-center justify-center w-6 h-6 rounded-full bg-gray-300 text-gray-700 text-sm font-bold">
                            ?
                        </span>
                        {/* Tooltip */}
                        <div className="absolute hidden group-hover:block bg-white text-gray-700 text-sm p-2 rounded shadow-md whitespace-normal w-[400px] -top-10 left-3/4 transform translate-x-[10%] text-justify">
                            This chart shows the daily click trends of advertisements for each venue, helping you analyze the performance across different venues. 
                            And you can use the buttons on the left to switch between different Venues
                        </div>
                    </span>

                </h3>
                {loading ? (
                    <p className="text-lg font-semibold text-gray-500">Loading...</p>
                ) : (
                    <Line data={filteredSpotTotalsData} options={optionsForLineChart} />
                )}
            </div>

        </div>
    );
};

export default AdvLogDashboard;