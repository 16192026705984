import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { FaTrash, FaPlus } from 'react-icons/fa';
import FloatingFooter from './FloatingFooter';
import { Link } from 'react-router-dom';
import ConfirmModal from './ConfirmModal';

// 获取所有 location 的选项（根据 data 提取 locationInfo）
const getLocationOptions = (data) => {
    const locations = new Set(data.AllArticleIds.map(article => article.locationInfo));
    return Array.from(locations).map(location => ({ value: location, label: location }));
};

const ArticlePicker = ({ data, token }) => {

    const [hasChange, setHasChange] = useState(false);
    const [selectedSpot, setSelectedSpot] = useState(null);
    const [availableArticles, setAvailableArticles] = useState([]);
    const [selectedArticles, setSelectedArticles] = useState([]);
    const [selectedSearchQuery, setSelectedSearchQuery] = useState('');
    const [searchQuery, setSearchQuery] = useState('');

    const [isSortedAsc, setIsSortedAsc] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [masterEntryChange, setMasterEntryChange] = useState(null);
    const [tempMainArticleId, setTempMainArticleId] = useState(null);

    const [selectedLocationFilter, setSelectedLocationFilter] = useState([]);
    const [availableLocationFilter, setAvailableLocationFilter] = useState([]);

    const [originalArticles, setOriginalArticles] = useState([]);
    const [originalMainId, setOriginalMainId] = useState(null);

    const [isSelectedSortedAsc, setIsSelectedSortedAsc] = useState(true);


    useEffect(() => {
        // 如果 localStorage 里存了上次的 spotId
        const storedSpotId = localStorage.getItem('selectedSpot');
        if (storedSpotId && data.Spots.some(spot => String(spot.spotId) === storedSpotId)) {
            setSelectedSpot(storedSpotId);
        } else {
            setSelectedSpot(null);
        }
    }, [data.Spots]);


    const spotOptions = data.Spots.map(spot => ({
        value: spot.spotId,
        label: spot.spotname,
    }));

    useEffect(() => {
        if (selectedSpot) {
            // 根据选中的 spotId 找到数据
            const spot = data.Spots.find(s => s.spotId === selectedSpot);
            const spotArticles = spot ? spot.articles.map(a => a.articleId) : [];
            const remainingArticles = data.AllArticleIds.filter(
                article => !spotArticles.includes(article.articleId)
            );

            setSelectedArticles(spot ? spot.articles : []);
            setAvailableArticles(remainingArticles);
            setTempMainArticleId(null);

            // 3. 记录原始数据
            setOriginalArticles(spot ? spot.articles : []);
            const originalMain = spot?.articles.find(a => a.isMainArticle);
            setOriginalMainId(originalMain?.articleId || null);

            // 4. Spot 切换时，重置 hasChange 为 false
            setHasChange(false);

        } else {
            // 如果没有选 Spot，清空两边
            setSelectedArticles([]);
            setAvailableArticles([]);
            setTempMainArticleId(null);
            setOriginalArticles([]);
            setOriginalMainId(null);
            setHasChange(false);
        }
    }, [selectedSpot, data]);


    useEffect(() => {
        if (!selectedSpot) {
            // 如果没选 Spot，肯定没修改
            setHasChange(false);
            return;
        }

        const originalIds = new Set(originalArticles.map(a => a.articleId));
        const currentIds = new Set(selectedArticles.map(a => a.articleId));
    
        if (originalIds.size !== currentIds.size) {
            setHasChange(true);
            return;
        }

        for (let id of originalIds) {
            if (!currentIds.has(id)) {
                setHasChange(true);
                return;
            }
        }

        const currentMainArticleObj = selectedArticles.find(a => a.isMainArticle);
        const currentMainId = tempMainArticleId
            ? tempMainArticleId
            : currentMainArticleObj?.articleId || null;

        if (currentMainId !== originalMainId) {
            setHasChange(true);
            return;
        }

        // 如果都没变，就 false
        setHasChange(false);

    }, [selectedArticles, tempMainArticleId, originalArticles, originalMainId, selectedSpot]);




    const handleSpotChange = (selectedOption) => {
        if (selectedOption) {
            setSelectedSpot(selectedOption.value);
            localStorage.setItem('selectedSpot', selectedOption.value);
        } else {
            setSelectedSpot(null);
            localStorage.removeItem('selectedSpot');
        }
    };

    const addArticle = (article) => {
        setSelectedArticles([...selectedArticles, article]);
        setAvailableArticles(availableArticles.filter(a => a.articleId !== article.articleId));
    };

    const removeArticle = (article) => {
        setAvailableArticles([...availableArticles, article]);
        setSelectedArticles(selectedArticles.filter(a => a.articleId !== article.articleId));
    };

    const handleSelectedSearchChange = (e) => setSelectedSearchQuery(e.target.value.toLowerCase());
    const handleSearchChange = (e) => setSearchQuery(e.target.value.toLowerCase());

    const handleSortToggle = () => setIsSortedAsc(!isSortedAsc);

    const handleSelectedSortToggle = () => {
        setIsSelectedSortedAsc(!isSelectedSortedAsc);
    };


    const filteredSelectedLinks = selectedArticles
        .slice() // 创建副本以避免直接修改原数组
        .sort((a, b) => {


            const aIsEntry = (a.articleId === tempMainArticleId || (!tempMainArticleId && a.isMainArticle));
            const bIsEntry = (b.articleId === tempMainArticleId || (!tempMainArticleId && b.isMainArticle));
            if (aIsEntry && !bIsEntry) return -1;
            if (bIsEntry && !aIsEntry) return 1;

            // 如果都不是(或都一样) Entry，就根据 isSelectedSortedAsc 做普通排序
            if (isSelectedSortedAsc) {
                return a.articlename.localeCompare(b.articlename);
            } else {
                return b.articlename.localeCompare(a.articlename);
            }

        })
        .filter(article =>
            article.articlename.toLowerCase().includes(selectedSearchQuery)
        );

    const filteredLinks = availableArticles
        .filter(article =>
            article.articlename.toLowerCase().includes(searchQuery)
        )
        .sort((a, b) =>
            isSortedAsc ? a.articlename.localeCompare(b.articlename) : b.articlename.localeCompare(a.articlename)
        );

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const confirmSave = async () => {
        await handleSave();
        closeModal();
        window.location.reload();
    };

    const handleSave = async () => {
        try {
            // 如果需要修改 MasterEntry，则调用相关 API
            if (masterEntryChange) {
                const { previousMasterEntryArticleId, targetMasterArticleId } = masterEntryChange;

                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/article/updatemainarticlewithspotidandarticleid`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify({
                        spotId: selectedSpot,
                        previousMasterEntryArticleId,
                        targetMasterArticleId
                    })
                });

                if (!response.ok) {
                    throw new Error("Failed to update Main Article");
                }

                setMasterEntryChange(null); // 重置 MasterEntry 状态
                setTempMainArticleId(null); // 清空临时主文章状态

            }

            // 构建要连接的文章和 spot 数据
            const linkRequests = selectedArticles.map(article => ({
                spot_id: selectedSpot,
                article_id: article.articleId,
                startDate: "",  // 可以根据需求添加 startDate 和 endDate
                endDate: ""
            }));

            // 处理断开的文章，找到哪些文章是新添加的，哪些文章被移除了
            const spot = data.Spots.find(s => s.spotId === selectedSpot);
            const originalArticles = spot ? spot.articles.map(a => a.articleId) : [];
            const removedArticles = originalArticles.filter(id => !selectedArticles.some(article => article.articleId === id));

            const disconnectRequests = removedArticles.map(articleId => ({
                spot_id: selectedSpot,
                article_id: articleId
            }));

            // 如果有需要连接的文章
            if (linkRequests.length > 0) {
                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/article/linkarticletospot`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify(linkRequests)
                });

                if (!response.ok) {
                    throw new Error("Error linking articles to spot");
                }
            }

            // 如果有需要断开的文章
            if (disconnectRequests.length > 0) {
                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/article/disconnectarticlewithspot`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify(disconnectRequests)
                });

                if (!response.ok) {
                    throw new Error("Error disconnecting articles from spot");
                }
            }

            alert("Changes saved successfully!");

        } catch (error) {
            console.error("Error saving changes:", error);
            alert("An error occurred while saving changes.");
        }
    };

    const handleSetMainArticle = (targetMasterArticleId) => {
        const spot = data.Spots.find(s => s.spotId === selectedSpot);
        const previousMasterEntryArticle = spot.articles.find(a => a.isMainArticle);

        // 更新 masterEntryChange 和临时主文章状态
        setMasterEntryChange({
            previousMasterEntryArticleId: previousMasterEntryArticle?.articleId || null,
            targetMasterArticleId
        });
        setTempMainArticleId(targetMasterArticleId); // 临时显示为主文章
    };


    const locationOptions = getLocationOptions(data);

    return (
        <div className="p-4 bg-white shadow-md rounded-lg max-w-[1500px] mx-auto mt-5 min-h-[650px]">


            <div className="flex space-x-8 mb-6">

                <div className="w-1/2">

                    <label htmlFor="spot-select" className="block mb-2 text-lg font-semibold">Select Venue: </label>
                    <div className="flex items-center space-x-6">

                        <Select
                            id="spot-select"
                            options={spotOptions}
                            value={spotOptions.find(option => option.value === selectedSpot)}
                            onChange={handleSpotChange}
                            placeholder="Search or select a venue"
                            isClearable
                            className="w-full"
                        />

                    </div>
                </div>

            </div>



            <div className="flex space-x-8">

                <div className="w-1/2 border-r pr-4 ">
                    <h4 className="text-md font-semibold mb-2">Selected Articles</h4>

                    <div className="mb-4">

                        <div className="flex items-center space-x-2">
                            <input
                                type="text"
                                placeholder="Search selected articles"
                                value={selectedSearchQuery}
                                onChange={handleSelectedSearchChange}
                                className="flex-1 p-2 border border-gray-300 rounded"
                            />

                            <button
                                onClick={handleSelectedSortToggle}
                                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                            >
                                Sort {isSelectedSortedAsc ? 'Descending' : 'Ascending'}
                            </button>
                        </div>
                    </div>

                    {/* Filter by location for Selected Articles */}
                    <Select
                        isMulti
                        options={locationOptions}
                        value={selectedLocationFilter}
                        onChange={(selected) => setSelectedLocationFilter(selected || [])}
                        placeholder="Filter by location"
                        className="w-full mb-4"
                    />

                    <ul className="space-y-2 overflow-y-auto max-h-[550px]">
                        {filteredSelectedLinks
                            .filter(article =>
                                selectedLocationFilter.length === 0 ||
                                selectedLocationFilter.some(filter => article.locationInfo === filter.value)
                            )
                            .map(article => (
                                <li key={article.articleId} className="flex items-center justify-between space-x-2 w-">
                                    {/* 左侧的标题部分 */}
                                    <div className="flex items-center w-full truncate">
                                        <a
                                            href={`${window.location.origin}/articledetail/${article.articleId}`}
                                            className="text-gray-700 hover:underline truncate"
                                        >
                                            {article.articlename} - {article.locationInfo}
                                        </a>
                                        {/* 如果是 Entry Article，显示 Entry Banner */}
                                        {(article.articleId === tempMainArticleId || (!tempMainArticleId && article.isMainArticle)) && (
                                            <span className="ml-4 px-2 py-1 bg-green-100 text-green-600 text-xs rounded shrink-0">
                                                Entry Banner
                                            </span>
                                        )}
                                    </div>

                                    {/* 右侧的按钮部分 */}
                                    <div className="flex items-center space-x-2 shrink-0">
                                        {!(article.articleId === tempMainArticleId || (!tempMainArticleId && article.isMainArticle)) && (
                                            <button
                                                onClick={() => handleSetMainArticle(article.articleId)}
                                                className="px-4 py-1 bg-blue-500 text-white rounded hover:bg-blue-600 whitespace-nowrap"
                                            >
                                                Set As Entry Article
                                            </button>
                                        )}
                                        <button
                                            onClick={() => removeArticle(article)}
                                            className="px-2 py-2 bg-red-500 text-white rounded hover:bg-red-600"
                                        >
                                            <FaTrash />
                                        </button>
                                    </div>
                                </li>
                            ))}
                    </ul>
                </div>


                <div className="w-1/2 border-r pr-4 max-h-[550px] overflow-y-auto">
                    <h4 className="text-md font-semibold mb-4">Available Articles</h4>

                    {/* Search and "New Article" in one row */}
                    <div className="flex items-center space-x-4 mb-4">
                        <input
                            type="text"
                            placeholder="Search available articles"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            className="p-2 border border-gray-300 rounded w-1/2 mr-4"
                        />

                        <Link
                            to="/article"
                            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 mr-2"
                        >
                            New Article
                        </Link>


                        <button
                            onClick={handleSortToggle}
                            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                        >
                            Sort {isSortedAsc ? 'Descending' : 'Ascending'}
                        </button>


                    </div>

                    <div className="flex items-center space-x-4 mb-4">
                        <Select
                            isMulti
                            options={locationOptions}
                            value={availableLocationFilter}
                            onChange={(selected) => setAvailableLocationFilter(selected || [])}
                            placeholder="Filter by location"
                            className="w-full"
                        />
                    </div>

                    {/* Articles List */}
                    <div className="max-h-[400px] overflow-y-auto">
                        {filteredLinks
                            .filter(article =>
                                availableLocationFilter.length === 0 ||
                                availableLocationFilter.some(filter => article.locationInfo === filter.value)
                            )
                            .length > 0 ? (
                            <ul className="space-y-2">
                                {filteredLinks
                                    .filter(article =>
                                        availableLocationFilter.length === 0 ||
                                        availableLocationFilter.some(filter => article.locationInfo === filter.value)
                                    )
                                    .map(article => (
                                        <li key={article.articleId} className="flex items-center space-x-2">
                                            <a
                                                href={`${window.location.origin}/articledetail/${article.articleId}`}
                                                className="flex-grow text-gray-700 hover:underline"
                                            >
                                                {article.articlename} - {article.locationInfo}
                                            </a>
                                            <button
                                                onClick={() => addArticle(article)}
                                                className="px-2 py-2 bg-green-500 text-white rounded hover:bg-green-600"
                                            >
                                                <FaPlus />
                                            </button>
                                        </li>
                                    ))}
                            </ul>
                        ) : (
                            <p className="text-gray-500">No results found.</p>
                        )}
                    </div>
                </div>

            </div>


            <div className="text-right">

                <FloatingFooter hasChanges={hasChange} onSave={openModal} />

                <ConfirmModal
                    isOpen={isModalOpen}
                    onClose={closeModal}
                    onConfirm={confirmSave}
                    header="Confirm Save"
                    content="Are you sure you want to save the changes?"
                />
            </div>
        </div>
    );
};

export default ArticlePicker;
