import React, { useState, useEffect, useContext } from "react";
import AppContext from "../contexts/AppContext";
import { Line } from "react-chartjs-2";
import { Pie } from "react-chartjs-2";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import "chart.js/auto";

const SpotTrafficAnalysis = () => {
    const { selectedRole, token } = useContext(AppContext);
    const [trendData, setTrendData] = useState([]);
    const [regionData, setRegionData] = useState([]);

    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1); 
    const sevenDaysAgo = new Date(yesterday);
    sevenDaysAgo.setDate(yesterday.getDate() - 7); 
    const [startDate, setStartDate] = useState(sevenDaysAgo); 
    const [endDate, setEndDate] = useState(yesterday); 
    const [loading, setLoading] = useState(false);
    const [selectedDate, setSelectedDate] = useState("all"); 
    const [platformData, setPlatformData] = useState([]);
    const [timeRange, setTimeRange] = useState("7"); // 默认为过去 7 天

    const [maxTicks, setMaxTicks] = useState(window.innerWidth < 640 ? 4 : 20);

    useEffect(() => {
        const handleResize = () => {
            setMaxTicks(window.innerWidth < 640 ? 4 : 20);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const handleTimeRangeChange = (e) => {
        const range = e.target.value;
        setTimeRange(range);

        const newEndDate = new Date(yesterday);
        let newStartDate = new Date(newEndDate);

        if (range === "7") {
            newStartDate.setDate(newEndDate.getDate() - 7);
        } else if (range === "15") {
            newStartDate.setDate(newEndDate.getDate() - 15);
        } else if (range === "30") {
            newStartDate.setMonth(newEndDate.getMonth() - 1);
        }else if (range === "90") {
            newStartDate.setMonth(newEndDate.getMonth() - 3);
        }
        setStartDate(newStartDate);
        setEndDate(newEndDate);
    };

    // 监听用户手动调整日期
    const handleStartDateChange = (date) => {
        if (date > endDate) {
            setEndDate(date);
        }
        setStartDate(date);
        setTimeRange("custom"); // 设置为 custom
    };

    const handleEndDateChange = (date) => {
        if (date < startDate) {
            setStartDate(date);
        }
        setEndDate(date);
        setTimeRange("custom"); // 设置为 custom
    };


    useEffect(() => {
        const fetchTrafficData = async () => {
            try {
                setLoading(true);

                const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                if (!selectedRole || !userTimeZone) {
                    throw new Error("Missing spotId or userTimeZone");
                }

                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/analysis/getspottrafficandregion`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        spotId: selectedRole.id,
                        startDate,
                        endDate,
                        userTimeZone,
                    }),
                });

                const platformResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/analysis/getuserplatformforspot`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        spotId: selectedRole.id,
                        startDate,
                        endDate,
                        userTimeZone,
                    }),
                });

                const data = await response.json();
                const platformData = await platformResponse.json();

                setTrendData(data.trend || []);
                setRegionData(data.region || []);
                setPlatformData(platformData || []);
                setSelectedDate("all");
            } catch (error) {
                console.error("Failed to fetch data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchTrafficData();
    }, [startDate, endDate, selectedRole, token]); // 确保依赖项正确设置

    const handleSelectedDateChange = (e) => {
        setSelectedDate(e.target.value);
    };

    const renderTrendChart = () => {
        const labels = trendData.map((item) => item.date);
        const durations = trendData.map((item) => item.totalDuration);

        const data = {
            labels,
            datasets: [
                {
                    label: "Duration by Date (min)",
                    data: durations,
                    borderColor: "rgba(75,192,192,1)",
                    backgroundColor: "rgba(75,192,192,0.2)",
                },
            ],
        };

        const options = {
            responsive: true,
            maintainAspectRatio: false, // 宽高比不固定
            plugins: {
                legend: {
                    onClick: () => {}, // 禁用点击隐藏数据功能
                },
            },
            scales: {
                x: {
                    title: {
                        display: true, // 显示标题
                        text: 'Time (Date)', // x轴单位
                        font: {
                            size: 14, // 设置字体大小
                            weight: 'bold', // 字体加粗
                        },
                        color: '#4B5563', // 设置标题颜色
                    },
                    ticks: {
                        maxTicksLimit: maxTicks, // 限制最多显示的刻度
                    },
                },
                y: {
                    title: {
                        display: true, // 显示标题
                        text: 'Minutes', // y轴单位
                        font: {
                            size: 14, // 设置字体大小
                            weight: 'bold', // 字体加粗
                        },
                        color: '#4B5563', // 设置标题颜色
                    },
                },
            },
        };



        return (
            <div className="w-full h-[35vh] md:h-[35vh]">
                {loading ? (
                    <p className="text-lg font-semibold text-gray-500">Loading...</p>
                ) : (
                    <Line data={data} options={options} />
                )}
            </div>
        );
    };

    const renderRegionChart = () => {
        const filteredRegionData =
            selectedDate === "all"
                ? regionData // 全部数据
                : regionData.filter((item) => item.date === selectedDate); // 按日期过滤

        const labels = [...new Set(filteredRegionData.map((item) => item.timeZone))];
        const durations = labels.map((label) =>
            filteredRegionData.filter((item) => item.timeZone === label).reduce((acc, curr) => acc + curr.totalDuration, 0)
        );

        const data = {
            labels,
            datasets: [
                {
                    label: selectedDate === "all" ? "Duration by Time Zone (All Dates, min)" : `Duration on ${selectedDate} (min)`,
                    data: durations,
                    backgroundColor: [
                        "#2A3E59",
                        "#54728C",
                        "#F2D98D",
                        "#A6786D",
                        "#D9665B",
                        "#898C1F",
                        "#3A403A"
                    ],
                },
            ],
        };

        const options = {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    onClick: () => {}, // 禁用点击隐藏数据功能
                },
                tooltip: {
                    callbacks: {
                        label: function (tooltipItem) {
                            const total = tooltipItem.dataset.data.reduce((acc, val) => acc + val, 0);
                            const value = tooltipItem.raw;
                            const percentage = ((value / total) * 100).toFixed(2);
                            return `${tooltipItem.label}: ${value} (${percentage}%)`;
                        },
                    },
                },
                datalabels: {
                    color: "#fff",
                    formatter: (value, context) => {
                        const total = context.chart.data.datasets[0].data.reduce((acc, val) => acc + val, 0);
                        const percentage = ((value / total) * 100).toFixed(2);
                        return `${percentage}%`;
                    },
                },
            },
        };

        return (
            <div className="w-full h-[300px] md:h-[250px]">

                {loading ? (
                    <p className="text-lg font-semibold text-gray-500">Loading...</p>
                ) : (
                    <Pie data={data} options={options} />
                )}
            </div>
        );
    };

    const renderPlatformChart = () => {
        // 根据选中的日期过滤数据
        const filteredPlatformData =
            selectedDate === "all"
                ? platformData // 如果是全部日期，直接使用所有数据
                : platformData.filter((item) => item.date === selectedDate); // 按日期筛选

        // 汇总所有数据
        const aggregateData = (field) => {
            if (selectedDate === "all") {
                const allItems = platformData.flatMap((item) => item.statistics[field]);
                const totalAmount = allItems.reduce((acc, curr) => acc + curr.amount, 0);
                const aggregated = allItems.reduce((acc, curr) => {
                    acc[curr.type] = (acc[curr.type] || 0) + curr.amount;
                    return acc;
                }, {});

                return {
                    labels: Object.keys(aggregated),
                    data: Object.values(aggregated),
                    percentages: Object.entries(aggregated).map(([type, amount]) => ({
                        type,
                        percentage: ((amount / totalAmount) * 100).toFixed(2),
                        amount,
                    })),
                };
            }

            // 如果不是 `all`，直接使用当天的数据
            const items = filteredPlatformData.length ? filteredPlatformData[0].statistics[field] : [];
            return {
                labels: items.map((item) => item.type),
                data: items.map((item) => item.amount),
            };
        };

        // 获取 Browser, OS 和 Device 的数据
        const browserStats = aggregateData("Browser");
        const osStats = aggregateData("os");
        const deviceStats = aggregateData("device");

        const options = {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    onClick: () => {}, // 禁用点击隐藏数据功能
                    position: "left", // 图例移到左侧
                },
                tooltip: {
                    callbacks: {
                        label: function (tooltipItem) {
                            // const total = tooltipItem.dataset.data.reduce((acc, val) => acc + val, 0);
                            const value = tooltipItem.raw;
                            // const percentage = ((value / total) * 100).toFixed(2);
                            return `${tooltipItem.label}: ${value} (number of times)`;
                        },
                    },
                },
                datalabels: {
                    color: "#fff",
                    formatter: (value, context) => {
                        const total = context.chart.data.datasets[0].data.reduce((acc, val) => acc + val, 0);
                        const percentage = ((value / total) * 100).toFixed(2);
                        return `${percentage}%`;
                    },
                },
            },
        };

        return (
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 items-start">
                <div className="flex flex-col items-center h-[300px] md:h-[250px] mb-10">
                    <h4 className="text-center font-semibold mb-4">Browser Usage
                    <span className="ml-2 group cursor-pointer inline-flex relative">
                        <span className="inline-flex items-center justify-center w-6 h-6 rounded-full bg-gray-300 text-gray-700 text-sm font-bold">
                            ?
                        </span>
                        {/* Tooltip: 使用标准类名 max-w-lg 并将容器设为relative */}
                        <div className="absolute hidden group-hover:block bg-white text-gray-700 text-sm p-2 rounded shadow-md whitespace-normal w-[400px] -top-10 left-1/2 transform translate-x-[10%] text-justify">
                        This chart shows the proportion of total visits attributed to different types of browsers used by users. 
                        You can click on the sections to view the exact number of visits
                        </div>
                    </span>
                    </h4>
                    <Pie
                        data={{
                            labels: browserStats.labels,
                            datasets: [
                                {
                                    label: "(number of times)",
                                    data: browserStats.data,
                                    backgroundColor: [
                                        "#032859",
                                        "#7A98BF",
                                        "#E3AD30",
                                        "#A69898",
                                        "#F0A8A9",
                                        "#B9BF8E",
                                        "#2C4138"
                                    ],
                                },
                            ],
                        }}
                        options={options}
                    />
                </div>
                <div className="flex flex-col items-center h-[300px] md:h-[250px] mb-10">
                    <h4 className="text-center font-semibold mb-4">Operating System Usage
                    <span className="ml-2 group cursor-pointer inline-flex relative">
                        <span className="inline-flex items-center justify-center w-6 h-6 rounded-full bg-gray-300 text-gray-700 text-sm font-bold">
                            ?
                        </span>
                        {/* Tooltip: 使用标准类名 max-w-lg 并将容器设为relative */}
                        <div className="absolute hidden group-hover:block bg-white text-gray-700 text-sm p-2 rounded shadow-md whitespace-normal w-[400px] -top-10 left-3/4 transform translate-x-[5%] text-justify">
                        This chart shows the proportion of total visits attributed to different types of Operating System used by users.
                        You can click on the sections to view the exact time in minutes.
                        </div>
                    </span></h4>
                    <Pie
                        data={{
                            labels: osStats.labels,
                            datasets: [
                                {
                                    label: "(number of times)",
                                    data: osStats.data,
                                    backgroundColor: [
                                        "#011C40",
                                        "#8AA6BF",
                                        "#FAAF40",
                                        "#736666",
                                        "#BF4E4E",
                                        "#5A7302",
                                        "#0F4001"
                                    ],
                                },
                            ],
                        }}
                        options={options}
                    />
                </div>
                <div className="flex flex-col items-center h-[300px] md:h-[250px]">
                    <h4 className="text-center font-semibold mb-4">Device Usage
                    <span className="ml-2 group cursor-pointer inline-flex relative">
                        <span className="inline-flex items-center justify-center w-6 h-6 rounded-full bg-gray-300 text-gray-700 text-sm font-bold">
                            ?
                        </span>
                        {/* Tooltip: 使用标准类名 max-w-lg 并将容器设为relative */}
                        <div className="absolute hidden group-hover:block bg-white text-gray-700 text-sm p-2 rounded shadow-md whitespace-normal w-[400px] -top-10 left-3/4 transform translate-x-[-110%] text-justify">
                        This chart shows the proportion of total visits attributed to different types of Device used by users.
                        You can click on the sections to view the exact time in minutes.
                        </div>
                    </span></h4>
                    <Pie
                        data={{
                            labels: deviceStats.labels,
                            datasets: [
                                {
                                    label: "(number of times)",
                                    data: deviceStats.data,
                                    backgroundColor: [
                                        "#074973",
                                        "#BFCDD9",
                                        "#FDD535",
                                        "#D9C6BF",
                                        "#F26163",
                                        "#6E8C03",
                                        "#284010"
                                    ],
                                },
                            ],
                        }}
                        options={options}
                    />
                </div>
            </div>
        );
    };

    return (
        <div className="relative flex flex-col gap-4 p-6 bg-gray-50 rounded-lg shadow-lg overflow-visible">

            <div className="flex gap-4 items-center">

                 {/* 时间范围下拉菜单 */}
                 <label className="text-gray-600 font-medium">
                    Time Range:
                    <select
                        value={timeRange}
                        onChange={handleTimeRangeChange}
                        className="ml-2 p-2 border rounded"
                    >
                        <option value="7">Past 7 Days</option>
                        <option value="15">Past 15 Days</option>
                        <option value="30">Past 1 Month</option>
                        <option value="90">Past 3 Month</option>
                        <option value="custom">Customrize</option>
                    </select>
                </label>


                {/* Start Date */}
                <label className="text-gray-600 font-medium">
                    Start Date:
                    <DatePicker
                        selected={startDate}
                        onChange={handleStartDateChange}
                        maxDate={new Date(new Date().setDate(new Date().getDate() - 1))}
                        className="border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 w-full sm:w-auto mt-2 sm:mt-0"
                        portalId="date-picker-index"
                    />
                </label>

                {/* End Date */}
                <label className="text-gray-600 font-medium">
                    End Date:
                    <DatePicker
                        selected={endDate}
                        onChange={handleEndDateChange}
                        minDate={startDate}
                        maxDate={new Date(new Date().setDate(new Date().getDate() - 1))}
                        className="border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 w-full sm:w-auto mt-2 sm:mt-0"
                    />
                </label>
            </div>

            <div className="h-auto w-full mb-4">
                <h3 className="text-l font-semibold mb-2 text-center flex items-center justify-center">
                    Browsing Time Analysis
                    <span className="ml-2 relative group cursor-pointer">
                        <span className="inline-flex items-center justify-center w-6 h-6 rounded-full bg-gray-300 text-gray-700 text-sm font-bold">
                            ?
                        </span>
                        {/* Tooltip */}
                        <div className="absolute hidden group-hover:block bg-white text-gray-700 text-sm p-2 rounded shadow-md whitespace-normal w-[400px] -top-10 left-3/4 transform translate-x-[10%] text-justify">
                            This chart shows the total period of visits to your web page per day in minutes
                        </div>
                    </span>
                </h3>

                <div className="h-auto w-full">
                    {trendData.length > 0 ? renderTrendChart() : <p>No trend data available.</p>}
                </div>
            </div>
            <div className=" h-auto mt-10">
                <label className="flex flex-col sm:inline-flex sm:items-center sm:gap-2 text-lg md:text-sm font-medium">
                    Select a Date for Region Analysis:
                    <select
                        value={selectedDate}
                        onChange={handleSelectedDateChange}
                        className="ml-0 sm:ml-2 p-2 border rounded"
                    >
                        <option value="all">All Dates</option>
                        {trendData.map((item) => (
                            <option key={item.date} value={item.date}>
                                {item.date}
                            </option>
                        ))}
                    </select>
                </label>
            </div>
            <div className="h-auto w-full mt-4 relative">

                <h3 className="text-l font-semibold mb-2 text-center">
                    Region Analysis
                    <span className="ml-2 group cursor-pointer inline-flex relative">
                        <span className="inline-flex items-center justify-center w-6 h-6 rounded-full bg-gray-300 text-gray-700 text-sm font-bold">
                            ?
                        </span>
                        {/* Tooltip: 使用标准类名 max-w-lg 并将容器设为relative */}
                        <div className="absolute hidden group-hover:block bg-white text-gray-700 text-sm p-2 rounded shadow-md whitespace-normal w-[400px] -top-10 left-3/4 transform translate-x-[10%] text-justify">
                            This chart shows the percentage of total viewing time of your pages in different time zones,
                            by clicking on the different parts of the pie chart you can see the exact time in minutes.
                        </div>
                    </span>
                </h3>

                {regionData.length > 0 ? renderRegionChart() : <p>No region data available.</p>}
            </div>

            <div className="h-auto w-full mb-4">
                <h3 className="text-l font-semibold mt-4 mb-2 text-center">
                    Equipment Analysis
                </h3>
                {platformData.length > 0 ? renderPlatformChart() : <p>No platform data available.</p>}
            </div>
        </div>
    );
};

export default SpotTrafficAnalysis;