import React, { useState, useContext, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import AppContext from "../contexts/AppContext";
import MapComponent from '../components/MapComponent';
import ConfirmModal from '../components/ConfirmModal'
import InformModal from '../components/InformModal';
import { useNavigate } from 'react-router-dom';

const InitialNewSpotOrAdv = () => {
  const { activeTab } = useParams();
  const { token, userInfo } = useContext(AppContext); // Add userInfo here
  const location = useLocation();
  const navigate = useNavigate();
  const tab = activeTab || 'venue';
  const GoogleMapApiKey = 'AIzaSyD63grvHYISPK4dLfyj5kKmC6MR_wQPJ80';
  const email = location.state?.userEmail || ''
  const accountName = location.state?.accountName || ''
  const userId = location.state?.userId || ''
  const isAccountActived = location.state?.accountStatus || false

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const [confirmAction, setConfirmAction] = useState(null); // "venue" or "adv"

  const [showInformModal, setShowInformModal] = useState(false);
  const [informContent, setInformContent] = useState('');

  const [informType, setInformType] = useState('inform');

  // Separate state for venue data and adv data
  const [venueData, setVenueData] = useState({
    email: userInfo.user.email,
    userId: userId,
    name: '',
    permissionLevel: '',
    geoLocation: { latitude: null, longitude: null, defaultZoom: 15 },
    logoImage: '',
    selectedAreaId: '',
    selectedLocationInfo: ''
  });

  const [advData, setAdvData] = useState({
    name: '',
    email: userInfo.user.email,
    spotIds: [], // Store selected spot IDs for Adv section
    permissionLevel: ''
  });

  const [spots, setSpots] = useState([]);
  const [areas, setAreas] = useState([]);
  const [locationInfos, setLocationInfos] = useState([]);

  useEffect(() => {

    // Attempt to fetch user's current location
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setVenueData((prevData) => ({
            ...prevData,
            geoLocation: {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
              defaultZoom: 15
            }
          }));
        },
        (error) => {
          console.error('Error fetching location:', error);
          setVenueData((prevData) => ({
            ...prevData,
            geoLocation: {
              latitude: -37.81485579713169,
              longitude: 144.95951682329178,
              defaultZoom: 15
            }
          }));
        },
        { enableHighAccuracy: true }
      );
    } else {
      setVenueData((prevData) => ({
        ...prevData,
        geoLocation: {
          latitude: -37.81485579713169,
          longitude: 144.95951682329178,
          defaultZoom: 15
        }
      }));
    }

    // Fetch Areas
    const fetchAreas = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/usersupport/areaList`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify({ email: userInfo.user.email }),
        });
        const data = await response.json();
        setAreas(data.areas);
      } catch (error) {
        console.error('Error fetching areas:', error);
      }
    };

    // Fetch Location Info
    const fetchLocationInfos = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/article/articleidandname`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify({ email: userInfo.user.email }),
        });
        const data = await response.json();
        setLocationInfos(data.locationInfoList);
      } catch (error) {
        console.error('Error fetching location infos:', error);
      }
    };

    // Fetch Spots for Adv
    const fetchSpots = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/spot/getallspotidandname`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });
        const data = await response.json();
        setSpots(data);
      } catch (error) {
        console.error('Error fetching spots:', error);
      }
    };

    fetchAreas();
    fetchLocationInfos();
    fetchSpots();
  }, [token, userInfo]);

  // const handleTabSwitch = (newTab) => setTab(newTab);

  const handleVenueInputChange = (e) => {
    const { name, value } = e.target;
    setVenueData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleLocationSelect = (location) => {
    setVenueData((prevData) => ({
      ...prevData,
      geoLocation: { latitude: location.latitude, longitude: location.longitude, defaultZoom: location.zoom }
    }));
  };

  const handleVenueSubmit = async () => {
    try {

      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/spot/createnewspot`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify(venueData),
        });

        // 检查状态码是否为 201 或 200
        if (response.status !== 201 && response.status !== 200) {
          const errorData = await response.json();
          throw new Error(`Error creating venue: ${errorData.message || 'Unexpected response status'}`);
        }

        const data = await response.json();
        console.log('Venue created:', data);

      } catch (error) {
        console.error('Error creating venue:', error);
        throw error;
      }

      if (isAccountActived) {

        const emailContent = {
          email: email,
          accountName: accountName,
          userId: userId,
          newAddedVenueName: venueData.name
        }

        const sendVenueActivationEmail = await fetch(`${process.env.REACT_APP_API_BASE_URL}/usersupport/activation/email/venue/addnew`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify(emailContent),
        });
        const emailResponse = await sendVenueActivationEmail.json();
        console.log('Venue created:', emailResponse);

      }
      else {

        const emailContent = {
          email: email,
          accountName: accountName,
          userId: userId,
          newVenueName: venueData.name
        }

        const sendVenueActivationEmail = await fetch(`${process.env.REACT_APP_API_BASE_URL}/usersupport/activation/email/venue/newuser`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify(emailContent),
        });
        const emailResponse = await sendVenueActivationEmail.json();
        console.log('Venue created:', emailResponse);

      }

    } catch (error) {
      console.error('Error creating venue:', error);
      throw error;
    }
  };

  const handleAdvSubmit = async () => {

    let data;

    try {

      try {
        const requestBody = {
          email: userInfo.user.email,
          userId: userId,
          name: advData.name,
          permissionLevel: advData.permissionLevel,
          spotIds: advData.spotIds
        };

        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/adv/createnewadvwithspotids`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify(requestBody),
        });

        // 检查状态码是否为 201
        if (response.status !== 201) {
          const errorData = await response.json();
          throw new Error(`Error creating venue: ${errorData.message || 'Unexpected response status'}`);
        }

        data = await response.json();
        console.log('Adv created:', data);
      } catch (error) {
        console.error('Error creating venue:', error);
        throw error;
      }

      const newAdvId = data?.adv?.id; // 安全访问 adv.id
      if (!newAdvId) {
        console.error('Error: newAdvId is undefined');
        return;
      }

      if (isAccountActived) {

        const emailContent = {
          email: email,
          accountName: accountName,
          userId: userId,
          newAddedAdvertisment: advData.name,
          spotIds: advData.spotIds,
          newAdvId: newAdvId
        }

        const sendVenueActivationEmail = await fetch(`${process.env.REACT_APP_API_BASE_URL}/usersupport/activation/email/advertisment/addnew`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify(emailContent),
        });
        const emailResponse = await sendVenueActivationEmail.json();
        console.log('Venue created:', emailResponse);

      }
      else {

        const emailContent = {
          email: email,
          accountName: accountName,
          userId: userId,
          newAdvertismentName: advData.name,
          spotIds: advData.spotIds,
          newAdvId: newAdvId
        }

        const sendVenueActivationEmail = await fetch(`${process.env.REACT_APP_API_BASE_URL}/usersupport/activation/email/advertisment/newuser`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify(emailContent),
        });
        const emailResponse = await sendVenueActivationEmail.json();
        console.log('Venue created:', emailResponse);

      }

    } catch (error) {
      console.error('Error creating adv:', error);
      throw error;
    }
  };

  const handleSpotCheck = (spotId) => {
    setAdvData((prevData) => {
      const spotIds = prevData.spotIds.includes(spotId)
        ? prevData.spotIds.filter(id => id !== spotId)
        : [...prevData.spotIds, spotId];
      return {
        ...prevData,
        spotIds
      };
    });
  };

  const handleAdvInputChange = (e) => {
    const { name, value } = e.target;
    setAdvData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleConfirmSubmit = async () => {
    // 先关掉确认弹窗
    setShowConfirmModal(false);

    try {
      if (confirmAction === 'venue') {
        await handleVenueSubmit();
        // 成功
        setInformContent("Venue created successfully!");
      } else {
        await handleAdvSubmit();
        // 成功
        setInformContent("Advertisement created successfully!");
      }
      setInformType('inform');
    } catch (error) {
      // 失败
      setInformContent(`Error: ${error.message}`);
      setInformType('error');
    }

    // 弹出通知
    setShowInformModal(true);

    // 3 秒后自动跳转，或者你也可以不自动跳转
    setTimeout(() => {
      navigate("/usermanagement");
    }, 3000);
  };


  const handleInformClose = () => {
    setShowInformModal(false);
    navigate("/usermanagement");
  };


  return (
    <div className='pl-0 lg:pl-64 pt-20'>
      <div className="p-4 bg-white shadow-md rounded-lg max-w-[1500px] mx-auto mt-5 min-h-[650px] mb-10">

        {tab === 'venue' && (
          <div className="mt-6 space-y-4">
            {/* Venue Inputs */}
            <div className="mx-4">
              <h1 className="ml-4 mb-4">Venue Name</h1>
              <input
                type="text"
                name="name"
                value={venueData.name}
                onChange={handleVenueInputChange}
                className="w-full p-2 border border-gray-500 rounded-md"
              />
            </div>

            <div className="mx-4 mt-4">
              <h1 className="ml-4 mb-4">Offering package</h1>
              <select
                name="permissionLevel"
                value={venueData.permissionLevel}
                onChange={(e) => {
                  const value = parseInt(e.target.value, 10); // 转换为整数
                  handleVenueInputChange({ target: { name: 'permissionLevel', value } });
                }}
                className="w-full p-2 border border-gray-500 rounded-md"
              >
                <option value="" disabled>
                  Please select a package
                </option>
                <option value={0}>Professional - 39 AUD per month</option>
                <option value={2}>Premium - 49 AUD per month</option>
              </select>
            </div>

            <div className="mx-4 mt-4">
              <h1 className="ml-4 mb-4">Discover Page Area</h1>
              <select
                name="selectedAreaId"
                value={venueData.selectedAreaId}
                onChange={handleVenueInputChange}
                className="w-full p-2 border border-gray-500 rounded-md"
              >
                <option value="">Select an Area</option>
                {areas.map((area) => (
                  <option key={area["Area Id"]} value={area["Area Id"]}>
                    {area["Area Name"]}
                  </option>
                ))}
              </select>
            </div>

            <div className="mx-4 mt-4">
              <h1 className="ml-4 mb-4">Linked Article Location</h1>
              <select
                name="selectedLocationInfo"
                value={venueData.selectedLocationInfo}
                onChange={handleVenueInputChange}
                className="w-full p-2 border border-gray-500 rounded-md"
              >
                <option value="">Select a Location</option>
                {locationInfos.map((location) => (
                  <option key={location} value={location}>
                    {location}
                  </option>
                ))}
              </select>
            </div>

            {venueData.geoLocation.latitude && (
              <div className='w-full'>
                <h1 className='ml-8'>Click the map to set location</h1>
                <MapComponent
                  apiKey={GoogleMapApiKey}
                  initialGeolocation={venueData.geoLocation}
                  onLocationSelect={handleLocationSelect}
                />
              </div>
            )}

            <div className="flex justify-end mr-10">

              <button
                onClick={() => navigate(-1)}
                className="px-4 py-2 mr-4 bg-gray-400 text-white rounded-md hover:bg-gray-500"
              >
                Back To User Management
              </button>

              <button
                onClick={() => {
                  setConfirmAction("venue");
                  setShowConfirmModal(true);
                }}
                className="bg-green-500 text-white px-10 py-2 rounded-md"
              >
                Submit
              </button>


            </div>
          </div>
        )}

        {tab === 'adv' && (
          <div className="mt-6 space-y-4">
            {/* Adv Inputs */}
            <div className="mx-4">
              <h1 className="ml-4 mb-4 font-semibold text-lg">Advertiser Name</h1>
              <input
                type="text"
                name="name"
                value={advData.name}
                onChange={handleAdvInputChange}
                className="w-full p-2 border border-gray-500 rounded-md"
              />
            </div>

            <div className="mx-4 mt-4">
              <h1 className="ml-4 mb-4">Offering package</h1>
              <select
                name="permissionLevel"
                value={advData.permissionLevel}
                onChange={(e) => {
                  const value = parseInt(e.target.value, 10); // 转换为整数
                  handleAdvInputChange({ target: { name: 'permissionLevel', value } });
                }}
                className="w-full p-2 border border-gray-500 rounded-md"
              >
                <option value="" disabled>
                  Please select a package
                </option>
                <option value={0}>Commercial - (As Contract) per month</option>
                <option value={2}>Wellfare - Free</option>
              </select>
            </div>

            <div className="mx-4 mt-4">
              <h1 className="ml-4 mb-4 font-semibold text-lg">Select Venues</h1>
              <div className="p-4 border border-gray-500 rounded-lg shadow-sm h-[35vh] overflow-y-auto">
                {spots.map(spot => (
                  <label key={spot.id} className="flex items-center space-x-2 cursor-pointer">
                    <input
                      type="checkbox"
                      checked={advData.spotIds.includes(spot.id)}
                      onChange={() => handleSpotCheck(spot.id)}
                      className="form-checkbox text-blue-500 h-5 w-5 cursor-pointer"
                    />
                    <span className="text-gray-700">{spot.name}</span>
                  </label>
                ))}
              </div>
            </div>

            <div className="flex justify-end mr-10">

              <button
                onClick={() => navigate(-1)}
                className="px-4 py-2 mr-4 bg-gray-400 text-white rounded-md hover:bg-gray-500"
              >
                Back To User Management
              </button>

              <button
                onClick={() => {
                  setConfirmAction("adv");
                  setShowConfirmModal(true);
                }}
                className="bg-green-500 text-white px-10 py-2 rounded-md"
              >
                Submit
              </button>

            </div>
          </div>
        )}
      </div>

      {/* 确认弹窗 */}
      <ConfirmModal
        isOpen={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        onConfirm={handleConfirmSubmit}
        header="Confirm Submission"
        content={`Are you sure you want to create a new ${confirmAction === 'venue' ? 'Venue' : 'Advertisement'
          }?`}
        confirmText="Confirm"
        cancelText="Cancel"
      />

      {/* 通知弹窗 */}
      <InformModal
        isOpen={showInformModal}
        onClose={handleInformClose}
        content={informContent}
        type={informType}
      />

    </div>
  );
};

export default InitialNewSpotOrAdv;