import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate, useSearchParams} from 'react-router-dom';
import AppContext from "../contexts/AppContext";

const EditVenue = () => {
  const { spotId } = useParams(); // 获取路由参数中的 spotId
  const { token, userInfo } = useContext(AppContext);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const spotName = searchParams.get('spotName') || 'Default Spot Name'; // 从查询参数中获取 spotName

  // 初始化 venueData，Venue Name 由 spotName 预填充
  const [venueData, setVenueData] = useState({
    name: spotName, // Venue Name 默认填充为 spotName
    venuePublicContentId: '',
    currentAreaId: '',
    selectedAreaId: '' // 默认 selectedAreaId 为空
  });

  const [areas, setAreas] = useState([]); // 可选择的区域列表
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchVenuePublicIdAndAreaId = async () => {
      try {
        const requestBody = {
          email: userInfo.user.email,
          spotId: spotId
        };

        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/spot/area/puclicpoint`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify(requestBody),
        });

        if (!response.ok) {
          throw new Error('Failed to fetch venue details');
        }

        const data = await response.json();

        // 根据接口返回数据更新 venueData，包括 currentAreaId 和 venuePublicContentId
        setVenueData((prevData) => ({
          ...prevData,
          venuePublicContentId: data.venuePublicContentId, // 填充 venuePublicContentId
          currentAreaId: data.currentAreaId, // 填充 currentAreaId
          selectedAreaId: data.currentAreaId // 默认 selectedAreaId 为 currentAreaId
        }));
        setLoading(false);
      } catch (err) {
        console.error(err);
        setError(err.message);
        setLoading(false);
      }
    };

    // 获取可选择的区域列表
    const fetchAreas = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/usersupport/areaList`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify({ email: userInfo.user.email }),
        });
        const data = await response.json();
        setAreas(data.areas); // 填充区域列表
      } catch (error) {
        console.error('Error fetching areas:', error);
      }
    };

    fetchVenuePublicIdAndAreaId();
    fetchAreas();
  }, [spotId, token, userInfo]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setVenueData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    try {
      const requestBody = {
        email: userInfo.user.email,
        spotName: venueData.name, 
        spotId: spotId,
        venuePublicContent_id: venueData.venuePublicContentId, 
        former_area_id: venueData.currentAreaId, 
        later_area_id: venueData.selectedAreaId 
      };

      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/spot/area/spotname/update`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to update venue');
      }

      alert('Venue updated successfully');
      navigate('/usermanagement'); // 跳转到用户管理页面
    } catch (error) {
      console.error('Error updating venue:', error);
      alert(error.message);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className='pl-0 lg:pl-64 pt-20'>
      <div className="p-4 bg-white shadow-md rounded-lg max-w-[1500px] mx-auto mt-5 min-h-[650px] mb-10">
        <h1 className="text-xl font-semibold mb-6">Edit Venue</h1>

        <div className="space-y-6">
          <div>
            <label className="block mb-2">Venue Name</label>
            <input
              type="text"
              name="name"
              value={venueData.name} // 填充 Venue Name
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-500 rounded-md"
            />
          </div>

          <div>
            <label className="block mb-2">Discover Page Area</label>
            <select
              name="selectedAreaId"
              value={venueData.selectedAreaId} // 填充 selectedAreaId
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-500 rounded-md"
            >
              <option value="">Select an Area</option>
              {areas.map((area) => (
                <option key={area["Area Id"]} value={area["Area Id"]}>
                  {area["Area Name"]}
                </option>
              ))}
            </select>
          </div>

          <div className="flex justify-end">
            <button
              onClick={handleSubmit}
              className="bg-green-500 text-white px-6 py-2 rounded-md"
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditVenue;