import React, { useContext, useState, useEffect } from 'react';
import AppContext from "../contexts/AppContext";
import InputWithCounter from '../components/InputWithCounter';
import ConfirmModal from '../components/ConfirmModal';
import CustomPhoneInput from '../components/CustomPhoneInput';
import FloatingFooter from '../components/FloatingFooter';
import QRCodeGenerator from '../components/QRCodeGenerator';
import MapComponent from '../components/MapComponent';
import { useNavigate, useParams } from 'react-router-dom';

const Profile = () => {
  const { userInfo, token, selectedRole } = useContext(AppContext);
  const [editData, setEditData] = useState(null);
  const [originalData, setOriginalData] = useState(null)
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hasChange, setHasChange] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null);
  const [initialLanding, setInitialLanding] = useState(null);
  const [editedLanding, setEditedLanding] = useState(null);
  const GoogleMapApiKey = 'AIzaSyD63grvHYISPK4dLfyj5kKmC6MR_wQPJ80';
  const { activeTab } = useParams();
  const navigate = useNavigate();

  useEffect(() => {

    // 'AccountSetting' has been hidden
    const validTabs = [ 'QRcodeAndLink', 'MapLocation'];

    if (!activeTab) {
      navigate('/profile/QRcodeAndLink', { replace: true });
    } else if (activeTab && !validTabs.includes(activeTab)) {
      navigate('/profile/QRcodeAndLink', { replace: true });
    }
  }, [activeTab, navigate]);

  const handleTabChange = (tab) => {
    navigate(`/profile/${tab}`);
  };

  useEffect(() => {

    const fetchUserInfo = async () => {
      try {
        const currentUserId = userInfo.user.id;

        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/usersupport/getuserinfobyidforactivation`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ userId: currentUserId }),
        });

        if (!response.ok) {
          throw new Error('Failed to fetch user info');
        }

        const data = await response.json();
        setOriginalData(data)
        setEditData(data);

        const roleInfo = userInfo.roleInfo;
        if (selectedRole.type === 'spot') {
          setSelectedItem(roleInfo.spot.find(item => item.id === selectedRole.id));
        } else if (selectedRole.type === 'adv') {
          setSelectedItem(roleInfo.adv.find(item => item.id === selectedRole.id));
        }

        if (selectedItem) {
          const landingData = selectedItem.landing
          setInitialLanding(landingData)
          setEditedLanding(landingData)
        }

      } catch (error) {
        console.error('Error fetching user info:', error);
        alert('Failed to load user information.');
      } finally {
        setIsLoading(false);
      }
    };

    if (userInfo) {
      fetchUserInfo();
    }

  }, [token, userInfo, selectedRole, selectedItem]);


  useEffect(() => {

    const hasLandingChanged = JSON.stringify(editData) !== JSON.stringify(originalData);
    const hasGeochanged = JSON.stringify(initialLanding) !== JSON.stringify(editedLanding);

    setHasChange(hasLandingChanged || hasGeochanged)

  }, [editData, editedLanding, initialLanding, originalData]);

  // const handleTabChange = (tab) => {
  //   setActiveTab(tab);
  // };

  const handleUserAccountNameUpdate = async (field, value) => {
    setEditData((prev) => ({
      ...prev,
      user: {
        ...prev.user,
        [field]: value
      },
    }));
  };

  const handleSelectedUserContactUpdate = async (section, field, value) => {
    setEditData((prev) => ({
      ...prev,
      user: {
        ...prev.user,
        UserContact: {
          ...prev.user.UserContact,
          [section]: {
            ...prev.user.UserContact[section],
            [field]: value,
          },
        },
      },
    }));
  };

  const handleSave = async () => {
    if (!editData || !originalData) return;

    try {
      const { user: currentUser } = editData;
      const { user: originalUser } = originalData;

      // Check for email changes
      if (currentUser.AccountName !== originalUser.AccountName) {
        await fetch(`${process.env.REACT_APP_API_BASE_URL}/usersupport/updateusercredentialbyprofilepage`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify({
            userId: currentUser.id,
            updateKey: 'AccountName',
            updateValue: currentUser.AccountName,
          }),
        });

        alert("AccountName has been updated successfully.");
      }

      // Check for UserContact changes
      if (JSON.stringify(currentUser.UserContact) !== JSON.stringify(originalUser.UserContact)) {
        await fetch(`${process.env.REACT_APP_API_BASE_URL}/usersupport/updateusercredentialbyprofilepage`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify({
            userId: currentUser.id,
            updateKey: 'UserContact',
            updateValue: currentUser.UserContact,
          }),
        });
        alert("User contact information has been updated successfully.");
      }
    } catch (error) {
      console.error("Error saving changes:", error);
      alert("An error occurred while saving changes.");
    }

    const GeoRequestBody = {
      spot_id: selectedItem.id,
      contact: selectedItem.contact,
      landing: editedLanding,
      theme: selectedItem.theme
    };

    await fetch(`${process.env.REACT_APP_API_BASE_URL}/spot/changeproperty`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(GeoRequestBody),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to update contacts and landing info');

        }
        else {
          setEditedLanding({ ...GeoRequestBody.landing });  // 更新 landing 信息

          return response.json();
        }
      })
      .catch(error => {
        console.error('Error updating contacts and landing info:', error);
      });
  };

  // 打开模态框
  const openModal = () => {
    setIsModalOpen(true);
  };

  // 关闭模态框
  const closeModal = () => {
    setIsModalOpen(false);
  };

  // 确认保存并执行 handleSave
  const confirmSave = async () => {
    await handleSave();
    closeModal();
    // window.location.reload();
    setHasChange(false);
  };


  const handleLocationSelect = ({ latitude, longitude, zoom }) => {

    const updatedLanding = { ...editedLanding };

    const geoLocation = {
      latitude: latitude,
      longitude: longitude,
      defaultZoom: zoom,
    }

    updatedLanding.geoLocation = geoLocation;
    setEditedLanding(updatedLanding);

  };

  if (isLoading) {
    return <p className="text-center mt-10 text-gray-500">Loading user information...</p>;
  }

  if (!editData) {
    return <p className="text-center mt-10 text-gray-500">No user information available</p>;
  }

  return (
    <div className="pl-0 lg:pl-64 pt-20">
      <div className="flex">
        <div className="text-2xl text-blue-600 font-semibold ml-40 hidden md:block">
          Account Setting
        </div>
      </div>
      <div className="p-8 bg-white shadow-md rounded-lg max-w-[90%] mx-auto mt-5 overflow-y-auto">


        {/* small screen */}
        <div className="block md:hidden">
          <select
            value={activeTab}
            onChange={(e) => handleTabChange(e.target.value)}
            className="block w-full border-gray-300 rounded-md p-2"
          >
            {/* <option value="AccountSetting">Account Info</option> */}
            <option value="QRcodeAndLink">QRCode And Website Link</option>
            <option value="MapLocation">Venue Location</option>

          </select>
        </div>

        {/* large Screen Navigation */}
        <div className="border-b border-gray-200">
          {/* <nav className="flex space-x-4 overflow-x-auto whitespace-nowrap"> */}
          <nav className="hidden md:flex space-x-4">
            {/* <button
              onClick={() => handleTabChange('AccountSetting')}
              className={`px-4 py-2 ${activeTab === 'AccountSetting' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'}`}
            >
              Account Info
            </button> */}


            {selectedRole?.type === 'spot' && (

              <div>
                <button
                  onClick={() => handleTabChange('QRcodeAndLink')}
                  className={`px-4 py-2 ${activeTab === 'QRcodeAndLink' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'}`}
                >
                  QRCode And Website Link
                </button>

                <button
                  onClick={() => handleTabChange('MapLocation')}
                  className={`px-4 py-2 ${activeTab === 'MapLocation' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'}`}
                >
                  Venue Location
                </button>

              </div>
            )}

          </nav>
        </div>

        <div>

          {activeTab === 'AccountSetting' && (

            <div className='p-8'>
              <section>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
                  <div>
                    <label className="block text-sm font-bold text-gray-700 mb-2">Account Name</label>
                    <InputWithCounter
                      name="accountName"
                      type="text"
                      value={editData.user.AccountName || ''}
                      onChange={(e) => handleUserAccountNameUpdate('AccountName', e.target.value)}
                      className="w-full"
                    />
                  </div>
                </div>
              </section>

              <section>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
                  {/* Primary Section */}
                  <div>
                    <label className="block text-sm font-bold text-gray-700 mb-2">Primary Contact First Name</label>
                    <InputWithCounter
                      name="primaryFirstName"
                      type="text"
                      value={editData.user.UserContact?.primary?.firstName || ''}
                      onChange={(e) => handleSelectedUserContactUpdate('primary', 'firstName', e.target.value)}
                      className="w-full"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-bold text-gray-700 mb-2">Primary Contact Last Name</label>
                    <InputWithCounter
                      name="primarySecondName"
                      type="text"
                      value={editData.user.UserContact?.primary?.secondName || ''}
                      onChange={(e) => handleSelectedUserContactUpdate('primary', 'secondName', e.target.value)}
                      className="w-full"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-bold text-gray-700 mb-2">Primary Contact Email</label>
                    <InputWithCounter
                      name="primaryEmail"
                      type="email"
                      value={editData.user.UserContact?.primary?.email || ''}
                      onChange={(e) => handleSelectedUserContactUpdate('primary', 'email', e.target.value)}
                      className="w-full"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-bold text-gray-700 mb-5">Primary Contact Phone</label>
                    <CustomPhoneInput
                      value={editData.user.UserContact?.primary?.phone || ''}
                      onChange={(value) => handleSelectedUserContactUpdate('primary', 'phone', value)}
                    />
                  </div>

                  {/* Secondary Section */}
                  <div>
                    <label className="block text-sm font-bold text-gray-700 mb-2">Secondary Contact First Name</label>
                    <InputWithCounter
                      name="secondaryFirstName"
                      type="text"
                      value={editData.user.UserContact?.secondary?.firstName || ''}
                      onChange={(e) => handleSelectedUserContactUpdate('secondary', 'firstName', e.target.value)}
                      className="w-full"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-bold text-gray-700 mb-2">Secondary Contact Last Name</label>
                    <InputWithCounter
                      name="secondarySecondName"
                      type="text"
                      value={editData.user.UserContact?.secondary?.secondName || ''}
                      onChange={(e) => handleSelectedUserContactUpdate('secondary', 'secondName', e.target.value)}
                      className="w-full"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-bold text-gray-700 mb-2">Secondary Contact Email</label>
                    <InputWithCounter
                      name="secondaryEmail"
                      type="email"
                      value={editData.user.UserContact?.secondary?.email || ''}
                      onChange={(e) => handleSelectedUserContactUpdate('secondary', 'email', e.target.value)}
                      className="w-full"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-bold text-gray-700 mb-5">Secondary Contact Phone</label>
                    <CustomPhoneInput
                      value={editData.user.UserContact?.secondary?.phone || ''}
                      onChange={(value) => handleSelectedUserContactUpdate('secondary', 'phone', value)}
                    />
                  </div>
                </div>
              </section>
            </div>

          )}

          {activeTab === 'QRcodeAndLink' && selectedRole?.type === 'spot' && (
            <section>
              <QRCodeGenerator selectedRole={selectedRole} />
            </section>
          )}

          {/* Thrid Section */}
          {activeTab === 'MapLocation' && selectedRole?.type === 'spot' && editedLanding && editedLanding.geoLocation && (
            <div className='p-4'>
            
                <h1 className='ml-4 font-semibold'>Click the map to set location</h1>
                <MapComponent
                  apiKey={GoogleMapApiKey}
                  initialGeolocation={editedLanding.geoLocation}
                  onLocationSelect={handleLocationSelect} />
            </div>
          )}

        </div>

        {/* Save Button */}
        <div className="text-right">
          <FloatingFooter hasChanges={hasChange} onSave={openModal} />
          {/* Confirm Modal */}
          <ConfirmModal
            isOpen={isModalOpen}
            onClose={closeModal}
            onConfirm={confirmSave}
            header="Confirm Save"
            content="Are you sure you want to save your profile?"
          />
        </div>
      </div>
    </div>
  );
};

export default Profile;