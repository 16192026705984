import React, { useState, useContext, useEffect, useCallback } from 'react';
import AppContext from "../contexts/AppContext";
import ConfirmModal from '../components/ConfirmModal';

const RoleBinding = () => {
  const { token, userInfo } = useContext(AppContext);
  const [followers, setFollowers] = useState([]);
  const [newFollowerEmail, setNewFollowerEmail] = useState('');
  const [newFollowerName, setNewFollowerName] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const [isCheckModalOpen, setIsCheckModalOpen] = useState(false);
  const [pendingDeleteUser, setPendingDeleteUser] = useState(null);

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  const fetchFollowers = useCallback(async () => {
    setLoading(true);
    setError('');
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/usersupport/follower/list`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ email: userInfo.user.email }),
      });
      if (!response.ok) throw new Error('Failed to fetch followers');
      const data = await response.json();
      setFollowers(data.users);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, [token, userInfo.user.email]);

  useEffect(() => {
    fetchFollowers();
  }, [fetchFollowers]);

  const addFollower = async () => {
    if (!newFollowerEmail || !newFollowerName) return;
    setLoading(true);
    setError('');
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/usersupport/follower/add`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ addedEmail: newFollowerEmail, addedAccountName: newFollowerName, email: userInfo.user.email }),
      });
      if (!response.ok) throw new Error('Failed to add follower');
      setNewFollowerName('');
      setNewFollowerEmail('');
      fetchFollowers();
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const deleteFollower = async (userEmail) => {
    setLoading(true);
    setError('');
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/usersupport/follower/delete`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ deletedEmail: userEmail, email: userInfo.user.email }),
      });
      if (!response.ok) throw new Error('Failed to delete follower');
      fetchFollowers();
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };


  const handleAddClick = () => {
    if (!newFollowerEmail || !newFollowerName) {
      setError("Please enter both email and name before confirming.");
      return;
    }
    setIsAddModalOpen(true); 
  };

 
  const confirmAddFollower = () => {
  
    setIsAddModalOpen(false);
  
    addFollower();
  };

  return (
    <div className="w-full max-w-7xl mx-auto px-2 lg:pl-64 pl-0 pt-20">
      <div className="text-3xl text-blue-600 font-semibold py-4 hidden md:block">Manage Followers</div>
      {error && <p className="text-red-500 mb-4">{error}</p>}
      {loading ? (
        <p className="text-blue-500">Loading...</p>
      ) : (
        <div className="bg-white shadow-md rounded-lg">
          <div className="flex items-center justify-between px-6 py-4 border-b border-gray-200">
            <h2 className="text-xl font-semibold">Follower List</h2>
          </div>
          <ul className="space-y-2 px-6 py-4">
            {followers.map((follower) => (
              <li
                key={follower.email}
                className="flex justify-between items-center bg-gray-100 p-2 rounded-md"
              >
                <span>{follower.email}</span>
                <span>{follower.AccountName}</span>
                <button
                  onClick={() => { setPendingDeleteUser(follower.email); setIsCheckModalOpen(true) }}
                  className="px-3 py-1 bg-red-500 text-white rounded-md hover:bg-red-600"
                >
                  Delete
                </button>
              </li>
            ))}
          </ul>
          <div className="px-6 py-4 border-t border-gray-200">
            <h2 className="text-xl font-semibold mb-2">Add New Follower</h2>
            <div className="flex space-x-2">
              <input
                type="text"
                value={newFollowerEmail}
                onChange={(e) => setNewFollowerEmail(e.target.value)}
                placeholder="Enter user email"
                className="flex-1 p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                maxLength={40}
              />
              <input
                type="text"
                value={newFollowerName}
                onChange={(e) => setNewFollowerName(e.target.value)}
                placeholder="Enter user name"
                className="flex-1 p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                maxLength={30}
              />
              <button
                onClick={handleAddClick}
                className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
              >
                Add
              </button>
            </div>
          </div>
        </div>
      )}
      <ConfirmModal
        isOpen={isCheckModalOpen}
        onClose={() => { setIsCheckModalOpen(false) }}
        onConfirm={() => { deleteFollower(pendingDeleteUser); setIsCheckModalOpen(false); setPendingDeleteUser(null) }}
        header="Confirm Delete"
        content="Are you sure you want to save the changes?" />

        {/* 添加前确认的弹窗（新增） */}
        <ConfirmModal
        isOpen={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        onConfirm={confirmAddFollower}
        header="Confirm Add"
        content={`Are you sure you want to add the follower: ${newFollowerEmail} ?`}
      />
    </div>
  );
};

export default RoleBinding;
