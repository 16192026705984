import React, { useState } from "react";
import { SketchPicker } from "react-color"; // 导入调色板组件

function ColorPalette({ OriginalTheme, onChange }) {
  const initialColor = OriginalTheme.color || "#ffffff";
  const initialFontColor = OriginalTheme?.fontColor || "#000000";

  const [color, setColor] = useState(initialColor);
  const [fontColor, setFontColor] = useState(initialFontColor);

  const handleColorChange = (selectedColor) => {
    const hexColor = selectedColor.hex;
    setColor(hexColor);
    onChange && onChange({ color: hexColor, fontColor });
  };

  // 固定的黑色系选项
  const fontColorOptions = [
    { value: "#000000", label: "Black" },
    { value: "#1B1B1B", label: "Dark Black" },
    { value: "#363636", label: "Darker Gray" },
    { value: "#515151", label: "Gray" }
  ];

  // 监听字体色（由下拉菜单选择）
  const handleFontColorChange = (e) => {
    const selectedValue = e.target.value;
    setFontColor(selectedValue);
    // 通知父组件
    onChange && onChange({ color, fontColor: selectedValue });
  };



  return (
    <div className="p-8 bg-white border border-gray-100 rounded-md">

      <label className="block text-md font-bold text-gray-700 mb-2">
        Page Background Colour
      </label>
      {/* 调色板和预览区域 */}
      <div className="grid grid-cols-3 gap-4 items-center mt-6">

        {/* 颜色预览圆圈 */}
        <div className="col-span-1 flex justify-center">
          <div
            className="w-16 h-16 rounded-full border border-gray-300 shadow-md"
            style={{ backgroundColor: color }}
          ></div>
        </div>

        {/* 调色板 */}
        <div className="col-span-2 flex justify-center">
          <SketchPicker
            color={color}
            onChangeComplete={handleColorChange} // 监听颜色改变
            disableAlpha // 禁用透明度
            className="shadow-lg"
          />
        </div>
      </div>

      <label className="block text-md font-bold text-gray-700 mb-2 mt-6">
        Page Font Colour
      </label>

      {/* 下拉菜单和预览区域 */}
      <div className="grid grid-cols-3 gap-4 mt-10">

        {/* 字体颜色预览圆圈 */}
        <div className="col-span-1 flex justify-center">
          <div
            className="w-16 h-16 rounded-full border border-gray-300 shadow-md"
            style={{ backgroundColor: fontColor }}
          ></div>
        </div>

        {/* 下拉菜单（选择字体色） */}
        <div className="col-span-2 flex justify-center">

          <select
            value={fontColor}
            onChange={handleFontColorChange}
            className="border border-gray-300 rounded p-2 w-1/2"
          >
            {fontColorOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>

      </div>

    </div>
  );
}

export default ColorPalette;