import React, { useState, useEffect, useContext } from 'react';
import { Line, Bar } from 'react-chartjs-2';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import AppContext from '../contexts/AppContext';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const ContentLogAnalysis = () => {
    const { token, selectedRole, userInfo } = useContext(AppContext);

    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1); // 昨天
    const sevenDaysAgo = new Date(yesterday);
    sevenDaysAgo.setDate(yesterday.getDate() - 7); // 七天前
    const [startDate, setStartDate] = useState(sevenDaysAgo); // 默认设置为七天前
    const [endDate, setEndDate] = useState(yesterday); // 默认设置为昨天
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [range, setRange] = useState([0, 10]);
    const [maxTicks, setMaxTicks] = useState(window.innerWidth < 640 ? 4 : 20);
    const [timeRange, setTimeRange] = useState("7"); // 默认为过去 7 天

    useEffect(() => {
        const handleResize = () => {
            setMaxTicks(window.innerWidth < 640 ? 4 : 20);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const handleTimeRangeChange = (e) => {
        const range = e.target.value;
        setTimeRange(range);

        const newEndDate = new Date(yesterday);
        let newStartDate = new Date(newEndDate);

        if (range === "7") {
            newStartDate.setDate(newEndDate.getDate() - 7);
        } else if (range === "15") {
            newStartDate.setDate(newEndDate.getDate() - 15);
        } else if (range === "30") {
            newStartDate.setMonth(newEndDate.getMonth() - 1);
        } else if (range === "90") {
            newStartDate.setMonth(newEndDate.getMonth() - 3);
        }
        setStartDate(newStartDate);
        setEndDate(newEndDate);
    };

    // 监听用户手动调整日期
    const handleStartDateChange = (date) => {
        if (date > endDate) {
            setEndDate(date);
        }
        setStartDate(new Date(date.setHours(0, 0, 0, 0)));
        setTimeRange("custom"); // 设置为 custom
    };

    const handleEndDateChange = (date) => {
        if (date < startDate) {
            setStartDate(date);
        }
        setEndDate(new Date(date.setHours(23, 59, 59, 999)));
        setTimeRange("custom"); // 设置为 custom
    };

    // 格式化日期为 "YYYY-MM-DD HH:mm:ss"
    const formatDateTime = (date, time) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day} ${time}`;
    };

    useEffect(() => {
        const fetchAnalyticsData = async () => {
            if (!startDate || !endDate || !selectedRole?.id) {
                console.error('Missing required parameters:', { startDate, endDate, selectedRole });
                return;
            }

            setLoading(true);

            try {
                const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/analysis/getcontentlogsummary`;
                const response = await fetch(apiUrl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        spotId: selectedRole.id,
                        startDate: formatDateTime(startDate, '00:00:00'),
                        endDate: formatDateTime(endDate, '23:59:59'),
                        userTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    }),
                });



                if (response.ok) {
                    const result = await response.json();
                    setData(result);
                } else {
                    console.error('Failed to fetch data:', response.statusText);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchAnalyticsData();
    }, [startDate, endDate, selectedRole, token]);

    if (loading) {
        return <div>Loading...</div>;
    }


    const dates = data.map((entry) => entry.date);

    // 获取所有可能的 Contact 类别
    const allContactCategories = Array.from(
        new Set(data.flatMap((entry) => Object.keys(entry.Contact || {})))
    );

    // 创建趋势数据
    const createCategoryTrends = (type) => {
        return allContactCategories.map((category) => ({
            label: category,
            data: data.map((entry) => (entry[type] && entry[type][category]) || 0),
        }));
    };

    const contactTrends = createCategoryTrends('Contact');
    const mapTrends = createCategoryTrends('Map');

    const getColourForCategory = (category) => {
        const index = allContactCategories.indexOf(category);
        return colourPalette[index % colourPalette.length];
    };


    const totalSum = data.map((entry) => entry.totalContact + entry.totalMap + entry.totalContent);

    const processedContentData = Object.entries(data.reduce((acc, entry) => {
        for (const [name, value] of Object.entries(entry.Content || {})) {
            acc[name] = (acc[name] || 0) + value;
        }
        return acc;
    }, {}))
        .sort((a, b) => b[1] - a[1]); // 按数量降序排列

    const totalContentCount = processedContentData.length;
    const pageSize = 10;

    // 动态生成分页区间
    const rangeOptions = [];
    for (let i = 0; i < totalContentCount; i += pageSize) {
        const start = i + 1;
        const end = Math.min(i + pageSize, totalContentCount);
        rangeOptions.push({ start, end });
    }

    // 当前分页数据
    const currentContentData = processedContentData.slice(range[0], range[1]);
    const contentLabels = currentContentData.map(([name]) => name);
    const contentValues = currentContentData.map(([, value]) => value);

    const handleRangeChange = (event) => {
        const [start, end] = event.target.value.split('-').map(Number);
        setRange([start - 1, end]);
    };

    const colourPalette = ["#2A3E59", "#54728C", "#F2D98D", "#A6786D", "#D9665B", "#898C1F", "#3A403A", "#074973", "#BFCDD9", "#FDD535", "#D9C6BF", "#F26163", "#6E8C03", "#284010"];

    return (
        <div className="w-full p-2 bg-gray-50 rounded-lg shadow-lg">
            <h2 className="text-2xl font-bold text-center text-gray-800 mb-6">Concierge Content Analytics</h2>

            {/* Date Range Selectors */}
            <div className="flex flex-col gap-4 mb-6">
                <div className="flex flex-col sm:flex-row sm:items-center gap-4 w-[90%] mx-auto">

                    {/* 时间范围下拉菜单 */}
                    <label className="text-gray-600 font-medium">
                        Time Range:
                        <select
                            value={timeRange}
                            onChange={handleTimeRangeChange}
                            className="ml-2 p-2 border rounded"
                        >
                            <option value="7">Past 7 Days</option>
                            <option value="15">Past 15 Days</option>
                            <option value="30">Past 1 Month</option>
                            <option value="90">Past 3 Month</option>
                            <option value="custom">Customrize</option>
                        </select>
                    </label>


                    {/* Start Date */}
                    <div className="flex flex-col sm:flex-row sm:items-center gap-2">
                        <label className="text-gray-600 font-medium">Start Date:</label>
                        <DatePicker
                            selected={startDate}
                            onChange={handleStartDateChange}
                            className="border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 max-w-xs"
                        />
                    </div>

                    {/* End Date */}
                    <div className="flex flex-col sm:flex-row sm:items-center gap-2">
                        <label className="text-gray-600 font-medium">End Date:</label>
                        <DatePicker
                            selected={endDate}
                            onChange={handleEndDateChange}
                            className="border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 max-w-xs"
                        />
                    </div>
                </div>
            </div>

            {/* Total Trends */}
            <div className="w-full h-[60vh] md:h-[600px] bg-gray-50 p-4 mb-6">
                <h3 className="text-lg font-semibold text-gray-700 text-center mb-4">Daily Click Trends of Concierge Content
                    <span className="ml-2 relative group cursor-pointer">
                        <span className="inline-flex items-center justify-center w-6 h-6 rounded-full bg-gray-300 text-gray-700 text-sm font-bold">
                            ?
                        </span>
                        {/* Tooltip */}
                        <div className="absolute hidden group-hover:block bg-white text-gray-700 text-sm p-2 rounded shadow-md whitespace-normal w-[400px] -top-10 left-3/4 transform translate-x-[10%] text-justify">
                            This chart shows the total number of clicks per day for content you created (e.g., ANZ Bank under the Bank category)
                        </div>
                    </span>

                </h3>
                <Line
                    data={{
                        labels: dates,
                        datasets: [
                            {
                                label: 'Total Sum',
                                data: totalSum,
                                borderColor: 'rgba(54, 162, 235, 1)',
                                backgroundColor: 'rgba(54, 162, 235, 0.2)',
                            },
                        ],
                    }}
                    options={{
                        responsive: true,
                        maintainAspectRatio: false,
                        plugins: {
                            legend: {
                                onClick: () => { }, // 禁用点击隐藏数据功能
                            }
                        },
                        layout: {
                            padding: {
                                top: window.innerWidth < 640 ? 10 : 20, // 小屏幕减少图表顶部间距
                            },
                        },
                        scales: {
                            x: {
                                title: {
                                    display: true, // 显示标题
                                    text: 'Time (Date)', // x轴单位
                                    font: {
                                        size: 14, // 设置字体大小
                                        weight: 'bold', // 字体加粗
                                    },
                                    color: '#4B5563', // 设置标题颜色
                                },
                                ticks: {
                                    maxTicksLimit: maxTicks, // 限制最多显示的刻度
                                },
                            },
                            y: {
                                title: {
                                    display: true, // 显示标题
                                    text: 'Click counts', // y轴单位
                                    font: {
                                        size: 14, // 设置字体大小
                                        weight: 'bold', // 字体加粗
                                    },
                                    color: '#4B5563', // 设置标题颜色
                                },
                            },
                        }
                    }}
                />
            </div>

            {/* Contact Trends */}
            <div className="w-full h-[60vh] p-2 sm:p-4 md:p-6 bg-gray-50 mb-10">
                <h3 className="text-base sm:text-lg font-semibold text-gray-700 text-center mb-4">Contact Click Trends on Landing Page

                    <span className="ml-2 relative group cursor-pointer">
                        <span className="inline-flex items-center justify-center w-6 h-6 rounded-full bg-gray-300 text-gray-700 text-sm font-bold">
                            ?
                        </span>
                        {/* Tooltip */}
                        <div className="absolute hidden group-hover:block bg-white text-gray-700 text-sm p-2 rounded shadow-md whitespace-normal w-[400px] -top-10 left-3/4 transform translate-x-[10%] text-justify">
                            This chart shows the click counts, proportions, and trends for each contact method on the landing page.
                        </div>
                    </span>


                </h3>
                <Bar
                    data={{
                        labels: dates,
                        datasets: contactTrends.map((trend, index) => ({
                            label: trend.label,
                            data: trend.data,
                            backgroundColor: getColourForCategory(trend.label),
                            maxBarThickness: 30,
                        })),
                    }}
                    options={{
                        responsive: true,
                        maintainAspectRatio: false,
                        plugins: {
                            datalabels: {
                                display: false, // 禁用数据标签
                            }
                        },
                        scales: {
                            x: {
                                stacked: true,
                                ticks: {
                                    maxTicksLimit: maxTicks,
                                },
                                title: {
                                    display: true, // 显示标题
                                    text: 'Time (Date)', // x轴单位
                                    font: {
                                        size: 14, // 设置字体大小
                                        weight: 'bold', // 字体加粗
                                    },
                                    color: '#4B5563', // 设置标题颜色
                                }
                            },
                            y: {
                                stacked: true,
                                beginAtZero: true,
                                title: {
                                    display: true, // 显示标题
                                    text: 'Click counts', // y轴单位
                                    font: {
                                        size: 14, // 设置字体大小
                                        weight: 'bold', // 字体加粗
                                    },
                                    color: '#4B5563', // 设置标题颜色
                                }
                            }
                        }
                    }}
                />
            </div>

            {/* Map Trends */}
            {userInfo?.user?.permissionlevel === 9 && (
                <div className="bg-gray-50 p-4 rounded-lg shadow-md mb-6">
                    <h3 className="text-lg font-semibold text-gray-700 text-center mb-4">Map Trends</h3>
                    <Line
                        data={{
                            labels: dates,
                            datasets: mapTrends.map((trend) => ({
                                label: trend.label,
                                data: trend.data,
                                borderColor: `rgba(${Math.random() * 255}, ${Math.random() * 255}, ${Math.random() * 255}, 1)`,
                                backgroundColor: `rgba(${Math.random() * 255}, ${Math.random() * 255}, ${Math.random() * 255}, 0.2)`,
                            })),
                        }}
                        options={{
                            responsive: true,
                            plugins: {
                                title: {
                                    display: true,
                                    text: 'Map Categories Over Time',
                                },
                            },
                        }}
                    />
                </div>
            )}

            {/* Content Trends */}
            <div className=" w-full h-[70vh] bg-gray-50 mb-20">
                <h3 className="text-lg font-semibold text-gray-700 text-center mb-4">Content Click Rankings by Selected Time Range

                    <span className="ml-2 relative group cursor-pointer">
                        <span className="inline-flex items-center justify-center w-6 h-6 rounded-full bg-gray-300 text-gray-700 text-sm font-bold">
                            ?
                        </span>
                        {/* Tooltip */}
                        <div className="absolute hidden group-hover:block bg-white text-gray-700 text-sm p-2 rounded shadow-md whitespace-normal w-[400px] -top-10 left-3/4 transform translate-x-[10%] text-justify">
                        This chart displays the ranking of content (e.g., Westpac Bank under the Bank category) based on the number of clicks within the selected time range, helping you identify the most popular content.
                        </div>
                    </span>


                </h3>
                <div className="flex items-center justify-between mb-4">
                    <label className="text-gray-600 font-medium">
                        Select Range:
                        <select
                            onChange={handleRangeChange}
                            className="ml-2 border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                        >
                            {rangeOptions.map(({ start, end }) => (
                                <option key={`${start}-${end}`} value={`${start}-${end}`}>
                                    {`${start}-${end}`}
                                </option>
                            ))}
                        </select>
                    </label>
                </div>
                <Bar
                    data={{
                        labels: contentLabels,
                        datasets: [
                            {
                                label: 'Content Total',
                                data: contentValues,
                                backgroundColor: '#54728C',
                                borderColor: '#2A3E59',
                                borderWidth: 1,
                                maxBarThickness: 30, // 控制每个柱子的最大宽度
                            },
                        ],
                    }}
                    options={{
                        indexAxis: 'y', // 横向条形图
                        responsive: true,
                        maintainAspectRatio: false,
                        plugins: {
                                legend: {
                                    onClick: () => { }, // 禁用点击隐藏数据功能
                                },
                            datalabels: {
                                color: '#FFFFFF', // 设置数字颜色为白色
                                anchor: 'center', // 数字显示在柱子末尾
                                align: 'right', // 数字与柱子右对齐
                                font: {
                                    size: 10, // 调整数字字体大小
                                    weight: 'bold', // 设置数字字体加粗
                                },
                            },
                        },
                        scales: {
                            x: {
                                beginAtZero: true,
                                title: {
                                    display: true, // 显示标题
                                    text: 'Click counts', // x轴单位
                                    font: {
                                        size: 14, // 设置字体大小
                                        weight: 'bold', // 字体加粗
                                    },
                                    color: '#4B5563', // 设置标题颜色
                                },
                            },
                            y: {
                                ticks: {
                                    padding: 1,
                                    callback: function (value, index, values) {
                                        const label = this.getLabelForValue(value);
                                        if (window.innerWidth < 768 && label.length > 10) {
                                            return label.slice(0, 10) + '…';
                                        }
                                        return label;
                                    }
                                },
                                categoryPercentage: 2, // 调整每个分类的宽度百分比（越小间距越小）
                                barPercentage: 0.8, // 调整每个条形的宽度百分比
                                title: {
                                    display: true, // 显示标题
                                    text: 'Content Title', // y轴单位
                                    font: {
                                        size: 14, // 设置字体大小
                                        weight: 'bold', // 字体加粗
                                    },
                                    color: '#4B5563', // 设置标题颜色
                                },
                            }
                        },
                        layout: {
                            padding: {
                                top: 5, // 图表顶部的间距
                                bottom: 5, // 图表底部的间距
                            },
                        },
                    }}
                />
            </div>
        </div>
    );
};

export default ContentLogAnalysis;