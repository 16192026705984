import React, { useState } from 'react';
import { FaDownload, FaTrash } from "react-icons/fa";
import ConfirmModal from '../components/ConfirmModal';
import svgIcons from '../utils/svgIconList';

const NonLeafNode = ({ detail, setDetail, saveContent, handleImageDelete, handleImageChange }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [switchState, setSwitchState] = useState(detail.isLeaf);

  // S3 Icons URLs (update the base path to your S3 bucket path)
  const baseS3Url = 'https://pladdypusv2.s3.ap-southeast-2.amazonaws.com/public/spot_icons/';

  const handleSwitch = () => {
    setIsModalOpen(true);
  };

  const confirmSwitch = () => {
    setIsModalOpen(false);
    setSwitchState(!switchState);
    setDetail((prevDetail) => ({
      ...prevDetail,
      isLeaf: true, description: '', bannerImage: '',
      iconImage: '', carouselImages: [], mainText: ''
    }));
  };

  const handleIconSelect = (icon) => {
    setDetail((prevDetail) => ({
      ...prevDetail,
      iconImage: `${baseS3Url}${icon}`,
      brandImagePrivate: `${icon}`
    }));
  };

  const handleIconClear = () => {
    setDetail((prevDetail) => ({
      ...prevDetail,
      iconImage: '',
      brandImagePrivate: ''
    }));
  };

  return (
    <div>
      <div className="flex items-center justify-between">
        <button
          onClick={handleSwitch}
          className="px-4 text-sm bg-white text-red-500 rounded hover:underline"
        >
          Switch to Directory Entry?
        </button>
      </div>
      <ConfirmModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={confirmSwitch}
        header="Do you want to proceed?"
        content="Switching will clear all data and change to Content mode."
      />
      <div className="flex flex-col md:flex-row md:space-x-4">
        <div className='w-full md:w-1/2 p-1'>
          <label className='block mt-2'>
            <span className="block text-sm text-gray-700">
              Name
              <span className='text-xs'> &nbsp;{detail.name ? detail.name.length : 0}/30</span>
            </span>
            <input
              type="text"
              name="name"
              value={detail.name || ''}
              onChange={(e) => setDetail({ ...detail, name: e.target.value })}
              maxLength={30}
              className="mt-1 block w-full shadow-sm border-gray-400 bg-gray-100 text-gray-900"
            />
          </label>
          <label className='block mt-2'>
            <span className="block text-sm text-gray-700">
              Description
              <span className='text-xs'> &nbsp;{detail.description ? detail.description.length : 0}/60</span>
            </span>
            <textarea
              name="description"
              value={detail.description || ''}
              onChange={(e) => setDetail({ ...detail, description: e.target.value })}
              maxLength={60}
              className="mt-1 block w-full shadow-sm border-gray-400 bg-gray-100 text-gray-900"
            />
          </label>
          <label className='block mt-2'>
            <span className="block text-sm text-gray-700">
              How to show inner content
            </span>
            <span className="flex items-center">
              <span
                onClick={() => { setDetail({ ...detail, layoutStyle: detail.layoutStyle === "sidebar" ? "overlay" : "sidebar" }) }}
                className={`relative w-12 h-6 flex items-center rounded-full cursor-pointer ${detail.layoutStyle === "sidebar" ? 'bg-blue-500' : 'bg-gray-400'
                  }`}
              >
                <span
                  className={`absolute w-4 h-4 bg-white rounded-full shadow-md transform transition-transform duration-300 ${detail.layoutStyle === "sidebar" ? 'translate-x-6' : 'translate-x-1'
                    }`}
                ></span>
              </span>
              <p className="ml-4 text-md">{detail.layoutStyle === "sidebar" ? 'Show icon' : 'Show image'}</p>
            </span>
          </label>
          <div className='block mt-2'>
            <span className="block text-sm text-gray-700">Banner Image</span>
            <div className="flex items-center">
              <label className="mt-1 block w-full border-gray-300 items-center">
                <span className="px-2 py-1 bg-gray-100 text-gray-900 rounded-md hover:text-gray-400 border-gray-300 border-2 cursor-pointer">
                  Edit Banner
                </span>
                <input
                  type="file"
                  name="bannerImage"
                  onChange={(e) => { handleImageChange(e, 960, 540) }}
                  accept="image/*"
                  className="hidden"
                />
              </label>
            </div>
          </div>
          {detail.bannerImage && (
            <div className="mt-2 w-full relative">
              <img
                src={detail.bannerImage}
                alt="sideImage"
                className="w-full"
              />
              <a href={detail.bannerImage} download="sideImage.jpg">
                <FaDownload
                  className="absolute top-0 left-0 h-6 w-6 z-10 text-blue-500 cursor-pointer"
                />
              </a>
              <FaTrash
                className="absolute top-0 right-0 h-6 w-6 z-10 text-red-500 cursor-pointer"
                onClick={() => handleImageDelete("bannerImage")}
              />
            </div>
          )}

        </div>
        <div className='w-full md:w-1/2 p-1'>
          <div className="block mt-2">
            <div className='flex items-center gap-2'>
              <span className="inline-block text-sm text-gray-700">Display Icon</span>
              {detail.iconImage && (
                <img
                  src={detail.iconImage}
                  alt={detail.iconImage.replace('.svg', '')}
                  className="h-12 w-12 object-contain"
                />
              )}
              {detail.iconImage && (
                <FaTrash
                  className="h-4 w-4 text-red-500 cursor-pointer ml-auto"
                  onClick={handleIconClear}
                />
              )}
            </div>
            <div className="grid grid-cols-6 sm:grid-cols-8 gap-2 mt-2 max-h-60 overflow-y-scroll">
              {svgIcons.map((icon, index) => {
                const iconUrl = `${baseS3Url}${icon}`;
                const isSelected = detail.iconImage === iconUrl;
                return (
                  <div
                    key={index}
                    onClick={() => handleIconSelect(icon)}
                    className={`cursor-pointer p-1 max-w-12 max-h-12
                    ${isSelected ? 'border-2' : 'border'} ${isSelected ? 'border-blue-500' : 'border-gray-300'} rounded-md`}
                  >
                    <img
                      src={iconUrl}
                      alt={icon.replace('.svg', '')}
                      className="p-1 w-full h-full object-contain "
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default NonLeafNode;
