import React, { useState, useContext, useEffect } from 'react';
import AppContext from "../contexts/AppContext";
import { useNavigate } from 'react-router-dom';
import EditContactsAndLanding from '../components/ModifyContactAndlanding';
import ModifyAdvLanding from '../components/ModifyAdvLanding';

const Home = () => {
  const { token, selectedRole, userInfo } = useContext(AppContext);
  const [currentUserWholeInfo, setCurrentUserWholeInfo] = useState(null);
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {

    const contentApi = `${process.env.REACT_APP_API_BASE_URL}/usersupport/info`;
    fetch(contentApi, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })
      .then(response => {
        if (!response.ok) {
          navigate('/error');
        }
        return response.json();
      })
      .then(data => {
        setCurrentUserWholeInfo(data);
        const roleInfo = data.roleInfo;
        if (selectedRole.type === 'spot') {
          setSelectedItem(roleInfo.spot.find(item => item.id === selectedRole.id));
        } else if (selectedRole.type === 'adv') {
          setSelectedItem(roleInfo.adv.find(item => item.id === selectedRole.id));
        }

      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });


  }, [token, navigate, selectedRole]);


  if (!currentUserWholeInfo) {
    return <div>Loading...</div>;
  }

  return (
    <div className='pl-0 pb-16 lg:pl-64 pt-16'>
      {selectedItem && (
        <div>

          {selectedRole.type === 'spot' && (
            <div >
              <div className="text-2xl text-blue-600 font-semibold ml-40 hidden md:block">
                Pages Info Setting
              </div>
              <EditContactsAndLanding
                initialContacts={selectedItem.contact}
                initialLanding={selectedItem.landing}
                initialTheme={selectedItem.theme}
                spotId={selectedItem.id}
                token={token}
                userInfo={userInfo}
                onSave={(updatedData) => {
                  console.log('Data saved:', updatedData);
                }}
              />
            </div>
          )}

          {selectedRole.type === 'adv' && (

            <div>
              <div className="text-2xl text-blue-600 font-semibold ml-40 py-4 hidden md:block">
                Landing page Setting
              </div>

              <ModifyAdvLanding
                initialLanding={selectedItem}
                advId={selectedItem.id}
                token={token}
                onSave={(updatedData) => {
                  console.log('Data saved:', updatedData);
                }}
              />

            </div>

          )}

        </div>

      )}
    </div>
  );
};

export default Home;
