import React from "react";
import { FaTimes } from "react-icons/fa"; // 引入默认关闭图标

const SelectModal = ({
    isOpen,
    onClose,
    onLeftClick, onRightClick,
    header,
    content,
    leftText = "Cancel",
    rightText = "Confirm",
    closeIcon: CloseIcon = FaTimes, // 支持自定义关闭按钮图标，默认使用 FaTimes
}) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center z-1000 lg:pl-64 pl-0">
            <div
                className="fixed inset-0 bg-black opacity-40 z-1000"
                onClick={onClose} // 点击背景关闭
            ></div>
            <div className="bg-white p-6 rounded-lg shadow-lg max-w-lg w-full z-1000 relative text-center">
                {/* 关闭按钮 */}
                <button
                    className="absolute top-2 right-2 p-2 text-gray-500 hover:text-gray-700 transition duration-200"
                    onClick={onClose}
                >
                    <CloseIcon className="h-5 w-5" />
                </button>

                {/* 标题 */}
                <p className="text-2xl font-semibold mb-4 text-gray-800">{header}</p>

                {/* 内容 */}
                <p className="text-gray-600 mb-6 text-wrap">{content}</p>

                {/* 按钮 */}
                <div className="flex justify-center space-x-20">
                    <button
                        className="px-4 py-2 rounded-md bg-gray-200 hover:bg-gray-300 text-gray-700 transition duration-200"
                        onClick={onLeftClick}
                    >
                        {leftText}
                    </button>
                    <button
                        className="px-4 py-2 rounded-md bg-red-500 text-white hover:bg-red-600 transition duration-200"
                        onClick={onRightClick}
                    >
                        {rightText}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SelectModal;
