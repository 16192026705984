import React, { useEffect, useState, useContext } from 'react';
import { ReactSortable } from 'react-sortablejs';
import InputWithCounter from './InputWithCounter';
import { useNavigate, useParams } from 'react-router-dom';
import FloatingFooter from './FloatingFooter';
import ConfirmModal from './ConfirmModal';
import AppContext from '../contexts/AppContext';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


const ArticleForm = () => {
    
    const Font = ReactQuill.Quill.import('formats/font');
    Font.whitelist = ["Roboto"];
    ReactQuill.Quill.register(Font, true);

    const { token } = useContext(AppContext);
    const [paragraphs, setParagraphs] = useState([]);
    const [articleData, setArticleData] = useState({
        name: '',
        image: '',
        locationInfo: '',
        description: '',
        paragraphs: []
    });
    const [hasChanges, setHasChanges] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { activeTab } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const validTabs = ['article', 'article-paragraph'];
        if (!activeTab || !validTabs.includes(activeTab)) {
            navigate('/article/article', { replace: true });
        }
    }, [activeTab, navigate]);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const handleInputChange = (field, value) => {
        setArticleData(prevData => ({
            ...prevData,
            [field]: value
        }));
        setHasChanges(true);
    };

    const handleFileUpload = async (file, type, index) => {
        const formData = new FormData();
        formData.append('image', file);
        formData.append('width', 1920);
        formData.append('height', 1080);

        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/usersupport/uploadimage`, {
                method: 'POST',
                headers: { 'Authorization': `Bearer ${token}` },
                body: formData,
            });
            const data = await response.json();
            const fileUrl = data.fileUrl;

            // Update specific paragraph detail with the uploaded file URL
            if (type === 'carousel') {
                setParagraphs(prevParagraphs =>
                    prevParagraphs.map((p, i) =>
                        i === index ? { ...p, detail: [...p.detail, fileUrl] } : p
                    )
                );
            } else if (type === 'articleInfoImage') {
                setArticleData(prevData => ({
                    ...prevData,
                    image: fileUrl
                }));
                setHasChanges(true);
            }
            else {
                setParagraphs(prevParagraphs =>
                    prevParagraphs.map((p, i) =>
                        i === index ? { ...p, detail: fileUrl } : p
                    )
                );
            }
            setHasChanges(true);
        } catch (error) {
            console.error("Error uploading file:", error);
        }
    };

    const handleSave = async () => {
        const cleanedParagraphs = paragraphs.map(({ chosen, selected, ...rest }) => rest);
        const requestBody = {
            name: articleData.name,
            image: articleData.image,
            locationInfo: articleData.locationInfo,
            description: articleData.description,
            paragraphs: cleanedParagraphs
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/article/createnewarticle`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(requestBody),
            });
            if (!response.ok) throw new Error('Failed to create article');
            setHasChanges(false);
        } catch (error) {
            console.error('Error creating article:', error);
        }
    };

    const confirmSave = async () => {
        await handleSave();
        closeModal();
        setHasChanges(false);
    };

    const addParagraph = (type) => {
        if (type === 'carousel') {
            setParagraphs([...paragraphs, { type, detail: [] }]);
        } else {
            setParagraphs([...paragraphs, { type, detail: '' }]);
        }
        setHasChanges(true);
    };

    const updateParagraph = (index, detail) => {
        setParagraphs(paragraphs.map((p, i) => (i === index ? { ...p, detail } : p)));
        setHasChanges(true);
    };



    const handleCarouselImageUpload = (file, index) => {
        handleFileUpload(file, 'carousel', index);
    };

    const removeParagraph = (index) => {
        setParagraphs(paragraphs.filter((_, i) => i !== index));
        setHasChanges(true);
    };

    const removeCarouselImage = (paragraphIndex, imageIndex) => {
        setParagraphs(prevParagraphs =>
            prevParagraphs.map((p, i) =>
                i === paragraphIndex ? { ...p, detail: p.detail.filter((_, imgIdx) => imgIdx !== imageIndex) } : p
            )
        );
        setHasChanges(true);
    };

    const quillModules = {
        toolbar: [
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],  // 标题大小 (h1, h2, h3, h4, h5, h6, 常规文本)
            // [{ 'font':Font.whitelist }, { 'size': ['small', false, 'large', 'huge'] }],  // 字体和字体大小
            [{ 'size': ['small', false, 'large', 'huge'] }],  // 字体和字体大小
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],  // 列表和缩进
            [{ 'align': [] }],  // 对齐方式
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],  // 加粗、斜体、下划线、删除线、引用
            [{ 'script': 'sub' }, { 'script': 'super' }],  // 上标/下标
            [{ 'color': [] }, { 'background': [] }],  // 颜色和背景色
            ['link'],  // 链接、图片、视频和公式
            ['clean']  // 清除格式
        ],
    };


    return (
        <div>
            <div className="border-b border-gray-200">
                <nav className="flex space-x-4">
                    <button onClick={() => navigate('/article/article')} className={`px-4 py-2 ${activeTab === 'article' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'}`}>
                        Article Info
                    </button>
                    <button onClick={() => navigate('/article/article-paragraph')} className={`px-4 py-2 ${activeTab === 'article-paragraph' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'}`}>
                        Paragraphs
                    </button>
                </nav>
            </div>

            {activeTab === 'article' && (
                <div>
                    <label className="block text-md font-medium text-gray-700 mt-4">Article Title:</label>
                    <InputWithCounter type="text" value={articleData.name} maxLength={64} onChange={(e) => handleInputChange('name', e.target.value)} />

                    <label className="block text-md font-medium text-gray-700 mt-4">Article Description:</label>
                    <InputWithCounter type="text" value={articleData.description} maxLength={64} onChange={(e) => handleInputChange('description', e.target.value)} />

                    <label className="block text-md font-medium text-gray-700 mt-4">Location Info:</label>
                    <InputWithCounter type="text" value={articleData.locationInfo} maxLength={64} onChange={(e) => handleInputChange('locationInfo', e.target.value)} />

                    <label className="block text-md font-medium text-gray-700 mt-4">Image Uploading:</label>
                    <div className="flex items-center mt-4">
                        <div className="mr-4">
                            {articleData.image && (
                                <img src={articleData.image} alt="Uploaded" className="w-auto h-24" />
                            )}
                        </div>
                        <input type="file" onChange={(e) => handleFileUpload(e.target.files[0], 'articleInfoImage')} className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100" />
                    </div>
                </div>
            )}

            {activeTab === 'article-paragraph' && (
                <div className='overflow-y-auto mb-20'>
                    <label className="block text-md font-medium text-gray-700 mt-4 mb-4"> Click button to add paragraph sections:</label>
                    <ReactSortable list={paragraphs} setList={setParagraphs} className="space-y-4">
                        {paragraphs.map((paragraph, index) => (
                            <div key={index} className="paragraph-item p-4 border border-gray-300 rounded-lg bg-white shadow-md">
                                <div className="flex items-center mb-2 ">
                                    <div className="block w-full py-2 px-3 text-gray-700 font-medium">
                                        {paragraph.type === 'text' && 'Text Section'}
                                        {paragraph.type === 'image' && 'Image Section'}
                                        {paragraph.type === 'carousel' && 'Carousel Section'}
                                        {paragraph.type === 'video' && 'Video Section'}
                                    </div>

                                    <button
                                        type="button"
                                        onClick={() => removeParagraph(index)}
                                        className="ml-4 px-2 py-1 text-sm text-white bg-red-500 rounded-md hover:bg-red-600"
                                    >
                                        Remove
                                    </button>
                                </div>
                                {paragraph.type === 'text' && (
                                    <ReactQuill
                                        value={paragraph.detail}
                                        onChange={(value) => updateParagraph(index, value)}
                                        modules={quillModules}
                                        className="bg-gray-50 rounded-lg shadow-sm"
                                    />
                                )}
                                {paragraph.type === 'image' && (
                                    <div className="mt-2">
                                        <input
                                            type="file"
                                            accept=".jpg, .jpeg, .png, .gif, .bmp, .svg"
                                            onChange={(e) => handleFileUpload(e.target.files[0], 'image', index)}
                                            className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
                                        />
                                        {paragraph.detail && (
                                            <img src={paragraph.detail} alt="Uploaded" className="w-auto h-32 mt-4 rounded-md border" />
                                        )}
                                    </div>
                                )}
                                {paragraph.type === 'carousel' && (
                                    <div className="mt-2">
                                        <label className="block text-md font-medium text-gray-700">Upload Carousel Images:</label>
                                        <input
                                            type="file"
                                            accept=".jpg, .jpeg, .png, .gif, .bmp, .svg"
                                            onChange={(e) => handleCarouselImageUpload(e.target.files[0], index)}
                                            className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
                                        />
                                        <ReactSortable
                                            list={Array.isArray(paragraph.detail) ? paragraph.detail : []} // Ensure it's an array
                                            setList={(newList) => updateParagraph(index, newList)}
                                            className="flex flex-wrap gap-4 mt-4"
                                        >
                                            {Array.isArray(paragraph.detail) && paragraph.detail.map((imageUrl, imgIndex) => (
                                                <div key={imgIndex} className="relative">
                                                    <img src={imageUrl} alt={`Carousel ${imgIndex}`} className="w-auto h-32 rounded-md border" />
                                                    <span className="absolute top-0 left-0 bg-gray-500 text-white text-xs font-semibold rounded-md px-2 py-1 m-1">
                                                        {imgIndex + 1}
                                                    </span>
                                                    <button
                                                        type="button"
                                                        className="absolute top-0 right-0 bg-red-500 text-white text-xs rounded-md px-2 py-1 m-1"
                                                        onClick={() => removeCarouselImage(index, imgIndex)}
                                                    >
                                                        x
                                                    </button>
                                                </div>
                                            ))}
                                        </ReactSortable>
                                    </div>
                                )}
                                {paragraph.type === 'video' && (
                                    <div className="mt-2">
                                        <input
                                            type="file"
                                            accept=".mp4, .mov, .avi, .wmv, .mkv, .webm"
                                            onChange={(e) => handleFileUpload(e.target.files[0], 'video', index)}
                                            className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
                                        />
                                    </div>
                                )}
                            </div>
                        ))}
                    </ReactSortable>
                    <div className="flex justify-center space-x-4 mt-6 ">
                        <button type="button" onClick={() => addParagraph('text')} className="px-4 py-2 text-sm text-white bg-blue-500 rounded-md hover:bg-blue-600">Add Text</button>
                        <button type="button" onClick={() => addParagraph('image')} className="px-4 py-2 text-sm text-white bg-blue-500 rounded-md hover:bg-blue-600">Add Image</button>
                        <button type="button" onClick={() => addParagraph('carousel')} className="px-4 py-2 text-sm text-white bg-blue-500 rounded-md hover:bg-blue-600">Add Carousel</button>
                        <button type="button" onClick={() => addParagraph('video')} className="px-4 py-2 text-sm text-white bg-blue-500 rounded-md hover:bg-blue-600">Add Video</button>
                    </div>
                </div>
            )}

            <div className="text-right">
                <FloatingFooter hasChanges={hasChanges} onSave={openModal} />
                <ConfirmModal isOpen={isModalOpen} onClose={closeModal} onConfirm={confirmSave} header="Confirm Save" content="Are you sure you want to save the changes?" />
            </div>
        </div>
    );
};

export default ArticleForm;
