import React from 'react';

const InputWithCounter = ({ value, maxLength, onChange, label }) => {
  return (
    <div className="mt-2">
      <div className="flex justify-between items-center">
        {label && <label className="block text-sm font-medium text-gray-700">{label}</label>}
        <small className="text-gray-500 ml-auto">
          {value.length}/{maxLength} max
        </small>
      </div>
      <input
        type="text"
        value={value}
        maxLength={maxLength}
        onChange={onChange}
        className="block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
      />
    </div>
  );
};

export default InputWithCounter;
