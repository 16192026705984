import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const CustomPhoneInput = ({ value, onChange, label }) => {
  return (
    <div >
        <div className="flex justify-between items-center ">
        {label && <label className="block text-sm font-semibold text-gray-700 mb-4">{label}</label>}
        </div>
        
      <PhoneInput
        country={'au'} // Default country
        value={value}
        onChange={onChange}
        inputProps={{
          name: 'phone',
          required: true,
          autoFocus: true,
        }}
        inputStyle={{
          width: '100%',
          border: '1.5px solid #D1D5DB', // Tailwind's gray-300
          borderRadius: '0.375rem', // Tailwind's rounded-md
          boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)', // Tailwind's shadow-sm
          fontSize: '1rem', // Tailwind's sm:text-sm
          height: '2.5rem',
        }}
        buttonStyle={{
          backgroundColor: 'white',
          border: '1.5px solid #D1D5DB', // Tailwind's gray-300
        }}
        containerStyle={{
          marginTop: '0.35rem',
          position: 'relative',
        }}
        dropdownStyle={{
          backgroundColor: 'white',
          border: '1px solid #D1D5DB',
          borderRadius: '0.375rem',
          boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1)',
        }}
      />
    </div>
  );
};

export default CustomPhoneInput;
