import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AppContext from '../contexts/AppContext';

const RequireAuth = ({ children }) => {
  const { token, loading } = useContext(AppContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!loading && !token) {
      navigate('/login');
    }
  }, [token, loading, navigate]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return token ? children : null;
};

export default RequireAuth;
