import React, { useState, useContext } from 'react';
import {useNavigate } from 'react-router-dom';
import {ArrowRightStartOnRectangleIcon } from '@heroicons/react/24/outline';
import Cookies from 'js-cookie';
import ConfirmModal from './ConfirmModal';
import AppContext from '../contexts/AppContext';

const Navbar = () => {
  const { userInfo,setToken,setSelectedRole } = useContext(AppContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleConfirmLogout = () => {
    Cookies.remove('token');
    Cookies.remove('isMasterUser');
    setToken(null);
    setSelectedRole({ id: null, type: null });
    sessionStorage.clear();
    setIsModalOpen(false);
    navigate('/login');
  };

  return (
    <nav className="z-20 bg-gray-800 p-4 flex justify-between items-center fixed pl-12 lg:pl-64 pr-20 top-0 w-full">
      {/* Display User's Email */}
      <div className="text-white ml-8">
        Welcome! {userInfo?.user?.email || 'User email not found'}
      </div>

      {/* Logout Section on the Right */}
      <div className="flex items-center space-x-2">
        {/* Wrap the icon and text inside a single div to make both clickable */}
        <div className="flex items-center cursor-pointer" onClick={() => setIsModalOpen(true)}>
          <ArrowRightStartOnRectangleIcon className="h-6 w-6 text-white" />
          <span className="text-white ml-2">Logout</span>
        </div>
      </div>

      {/* Confirm Logout Modal */}
      <ConfirmModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleConfirmLogout}
        header="Confirm Logout"
        content="Are you sure you want to log out?"
      />
    </nav>
  );
};

export default Navbar;
