import React, { useState, useEffect } from 'react';
import { FaPlus, FaTrash, FaDownload } from 'react-icons/fa';
import ConfirmModal from './ConfirmModal';
import FloatingFooter from './FloatingFooter';
import InputWithCounter from './InputWithCounter';
import { useNavigate, useParams } from 'react-router-dom';

const EditAdvLanding = ({ landingInfo, onFileChange, onSpecialChange, onSpecialReorder, onSpecialAdd, message, showMainImage = true, showSpecials = true }) => {
  const maxSpecialsAllowed = 6;
  const getTodayDate = () => new Date().toISOString().split('T')[0];
  const todayDate = getTodayDate();

  const [specialDates, setSpecialDates] = useState(
    landingInfo && landingInfo.specials
      ? landingInfo.specials.map((special) => ({
        startDate: special.startTimestamp ? special.startTimestamp.split('T')[0] : getTodayDate(),
        endDate: special.endTimestamp ? special.endTimestamp.split('T')[0] : getTodayDate(),
      }))
      : []
  );

  const handleDeleteSpecial = (index) => {
    if (landingInfo.specials.length <= 1) return;
    const updatedSpecials = landingInfo.specials.filter((_, i) => i !== index);
    onSpecialReorder(updatedSpecials);
  };

  const handleAddSpecial = () => {
    const newSpecial = {
      specials: [''],
      startTimestamp: todayDate, // 默认今天的日期
      endTimestamp: todayDate, // 默认今天的日期
      image: '',
    };

    onSpecialAdd(newSpecial);

    // 模拟点击文件选择器
    const input = document.createElement('input');
    input.type = 'file';
    input.onchange = (e) => {
      const lastIndex = landingInfo.specials.length; // 新 special 的索引
      onFileChange(`specials_${lastIndex}_image`, e.target.files[0], 'specials'); // 上传文件并更新该 special 的图片
    };
    input.click(); // 触发文件选择
  };

  const handleStartDateChange = (index, value) => {
    const updatedDates = [...specialDates];
    if (!updatedDates[index]) {
      updatedDates[index] = { startDate: getTodayDate(), endDate: getTodayDate() };
    }
    updatedDates[index].startDate = value;
    setSpecialDates(updatedDates);
    onSpecialChange(index, 'startTimestamp', value);
  };

  const handleEndDateChange = (index, value) => {
    const updatedDates = [...specialDates];

    if (!updatedDates[index]) {
      updatedDates[index] = { startDate: getTodayDate(), endDate: getTodayDate() };
    }
    updatedDates[index].endDate = value;
    setSpecialDates(updatedDates);
    onSpecialChange(index, 'endTimestamp', value);
  };

  return (
    <div className="space-y-6 text-center">
      {landingInfo && (

        <div className="min-w-full bg-white border border-gray-200">

          {showMainImage && (
            <div className='h-[40vh]'>
              {/* Image Modification */}
              <div className="p-4 text-start mb-10">
                <h1 className='font-bold'>Main Image</h1>
              </div>

              {/* 使用 flex-wrap 使按钮和图片在小屏幕时换行 */}
              <div className="flex flex-wrap items-center space-x-4 mb-4 ml-4">
                <span className="text-center col-span-1 ml-20"></span>
                <div className="text-center w-full md:w-auto">
                  {landingInfo.image ? (
                    // eslint-disable-next-line jsx-a11y/img-redundant-alt
                    <img src={landingInfo.image} alt="Image Modification" className="w-auto h-[20vh]" />
                  ) : (
                    <span>No Image Uploaded</span>
                  )}
                </div>

                <div className="flex items-center space-x-4 md:space-x-6 w-auto">
                  <input
                    type="file"
                    onChange={(e) => onFileChange('image', e.target.files[0], 'image')}
                    className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
                  />

                  {/* 下载按钮在小屏幕和大屏幕的显示逻辑 */}
                  <a
                    href={landingInfo.image}
                    download
                    className="text-blue-500 hover:text-blue-700 md:hidden"
                  >
                    <FaDownload />
                  </a>

                  <a
                    href={landingInfo.logoImage}
                    download
                    className="text-blue-500 hover:text-blue-700 hidden md:flex"
                  >
                    <FaDownload />
                  </a>
                </div>

              </div>
            </div>
          )}

          {showSpecials &&
            (
              <div>

                {/* 显示 message 只渲染一次 */}
                {message && <p className="text-green-500">{message}</p>}

                {/* 循环 specials */}
                {landingInfo.specials.map((special, index) => (
                  <div key={index} className="border-b border-gray-300 p-4">

                    <div className="flex flex-wrap items-center space-x-4 mb-4">
                      <div className="p-4 w-8 text-center">
                        <span className="text-center col-span-1 text-md font-semibold bg-blue-700 text-white rounded-md w-6 h-6 flex items-center justify-center">{index + 1}</span>
                      </div>

                      <div className="flex-grow w-full md:w-auto">
                        {special.image && (
                          <img src={special.image} alt="Special" className="w-auto h-24 mx-auto" />
                        )}
                      </div>

                      <div className="flex-grow w-full md:w-auto">
                        <input
                          type="file"
                          onChange={(e) => onFileChange(`specials_${index}_image`, e.target.files[0], 'specials')}
                          className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
                        />
                      </div>

                      <div className="flex justify-end space-x-2 w-full md:w-auto">
                        <button
                          className={`text-red-500 hover:text-red-700 ${landingInfo.specials.length === 1 ? 'opacity-50 cursor-not-allowed' : ''}`}
                          onClick={() => handleDeleteSpecial(index)}
                          disabled={landingInfo.specials.length === 1}
                        >
                          <FaTrash />
                        </button>

                        <a href={special.image} download className="text-blue-500 hover:text-blue-700">
                          <FaDownload />
                        </a>
                      </div>
                    </div>

                    <div className="mt-2 space-y-4">
                      {/* StartDate and EndDate */}
                      <div className="flex space-x-4">
                        <div className="w-1/2">
                          <label className="block text-sm font-medium text-gray-700">Start Date</label>
                          <input
                            type="date"
                            value={specialDates[index]?.startDate || special.startTimestamp.split('T')[0]}
                            min={todayDate}
                            onChange={(e) => handleStartDateChange(index, e.target.value)}
                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                          />
                        </div>

                        <div className="w-1/2">
                          <label className="block text-sm font-medium text-gray-700">End Date</label>
                          <input
                            type="date"
                            value={specialDates[index]?.endDate || special.endTimestamp.split('T')[0]}
                            min={specialDates[index]?.startDate || todayDate}
                            onChange={(e) => handleEndDateChange(index, e.target.value)}
                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                          />
                        </div>
                      </div>
                    </div>

                  </div>
                ))}

                {/* Add New Special 按钮（只渲染一次） */}
                <div className="p-4 text-center">
                  {(!landingInfo.specials || landingInfo.specials.length < maxSpecialsAllowed) ? (
                    <button
                      type="button"
                      onClick={handleAddSpecial}
                      className="inline-flex items-center px-4 py-2 bg-blue-500 text-white font-medium rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      <FaPlus className="mr-2" />
                      Add New Special
                    </button>
                  ) : (
                    <p className="text-red-500">You have reached the maximum of 6 specials.</p>
                  )}
                </div>
              </div>
            )}

        </div>
      )}
    </div>
  );
};




const ModifyAdvLanding = ({ initialLanding, advId, token, onSave }) => {
  const [editedLanding, setEditedLanding] = useState(null);
  const [message, setMessage] = useState('');
  const [canSave, setCanSave] = useState(false);
  const [hasChanges, setHasChanges] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { activeTab } = useParams();
  const navigate = useNavigate();

  useEffect(() => {

    const validTabs = ['Image Modification And Alias', 'Specials'];

    if (!activeTab) {
      navigate('/landingpage/Image Modification And Alias', { replace: true });
    } else if (activeTab && !validTabs.includes(activeTab)) {
      navigate('/landingpage/Image Modification And Alias', { replace: true });
    }
  }, [activeTab, navigate]);

  const handleTabChange = (tab) => {
    navigate(`/landingpage/${tab}`);
  };

  useEffect(() => {
    setEditedLanding({
      ...initialLanding,
      specials: initialLanding.specials || [],
      image: initialLanding.image || '',
      alias: initialLanding.alias || ''
    })
  }, [initialLanding])

  const handleAddSpecial = (newSpecial) => {
    setEditedLanding((prev) => ({
      ...prev,
      specials: [...(prev.specials || []), newSpecial],
    }));
  };

  // 打开模态框
  const openModal = () => {
    setIsModalOpen(true);
  };

  // 关闭模态框
  const closeModal = () => {
    setIsModalOpen(false);
  };

  // 确认保存并执行 handleSave
  const confirmSave = () => {
    handleSave();
    closeModal(); // 保存后关闭模态框
  };

  const isDateRangeOverlapping = (startDate1, endDate1, startDate2, endDate2) => {
    return new Date(startDate1) <= new Date(endDate2) && new Date(startDate2) <= new Date(endDate1);
  };

  useEffect(() => {
    if (editedLanding && editedLanding.specials) {
      const allFieldsValid = Array.isArray(editedLanding.specials) && editedLanding.specials.every(
        (special) => {
          const isSpecialFilled = special.image;
          const isDateValid = special.startTimestamp && special.endTimestamp && new Date(special.startTimestamp) <= new Date(special.endTimestamp);

          return isSpecialFilled && isDateValid;
        }
      );

      // 检查日期范围是否重叠
      const noOverlap = editedLanding.specials.every((currentSpecial, currentIndex) => {
        return editedLanding.specials.every((otherSpecial, otherIndex) => {
          if (currentIndex === otherIndex) return true; // 跳过同一个 special
          return !isDateRangeOverlapping(
            currentSpecial.startTimestamp,
            currentSpecial.endTimestamp,
            otherSpecial.startTimestamp,
            otherSpecial.endTimestamp
          );
        });
      });

      setCanSave(allFieldsValid && noOverlap);

      if (!noOverlap) {
        setMessage('The date ranges of the specials are overlapping or someone has expired. Please adjust the dates.');
      } else {
        setMessage('');
        setHasChanges(true)
      }
    }
  }, [editedLanding]);




  const handleFileChange = (field, file, type) => {
    let width, height;

    if (type === 'image') {
      width = 960;
      height = 540; // 16:9 ratio
    } else if (type === 'specials') {
      width = 1920;
      height = 1080; // Set dimensions for special if needed
    }

    const formData = new FormData();
    formData.append('image', file);
    formData.append('width', width);
    formData.append('height', height);

    fetch(`${process.env.REACT_APP_API_BASE_URL}/usersupport/uploadimage`,
      {
        method: 'POST',
        headers: { 'Authorization': `Bearer ${token}` },
        body: formData,
      })
      .then(response => response.json())
      .then(data => {
        setMessage(data.message);

        const fileUrl = data.fileUrl;

        const updatedLanding = { ...editedLanding };


        if (field.startsWith('specials')) {
          const index = parseInt(field.split('_')[1], 10);

          if (!updatedLanding.specials[index]) {
            updatedLanding.specials[index] = {
              startTimestamp: '',
              endTimestamp: '',
              image: '',
            };
          }
        }


        if (field.startsWith('specials')) {
          const index = parseInt(field.split('_')[1], 10);
          updatedLanding.specials[index].image = fileUrl;
        } else {
          updatedLanding[field] = fileUrl;
        }

        setEditedLanding(updatedLanding);
        setHasChanges(true)
      })
      .catch(error => {
        console.error('Error uploading image:', error);
        setMessage('Failed to upload image');
      });
  };

  const handleSpecialChange = (index, field, value) => {
    const updatedSpecials = editedLanding.specials.map((special, i) =>
      i === index ? { ...special, [field]: value } : special
    );
    setEditedLanding({
      ...editedLanding,
      specials: updatedSpecials,
    });
  };

  const handleSpecialReorder = (reorderedSpecials) => {
    setEditedLanding({
      ...editedLanding,
      specials: reorderedSpecials,
    });
  };

  const handleSave = () => {


    const sortedSpecials = [...editedLanding.specials].sort((a, b) => {
      const dateA = new Date(a.endTimestamp);
      const dateB = new Date(b.endTimestamp);
      return dateA - dateB;
    });

    // Convert string dates to Date objects before saving
    const formattedSpecials = sortedSpecials.map(special => ({
      ...special,
      startTimestamp: new Date(special.startTimestamp),
      endTimestamp: new Date(special.endTimestamp)
    }));

    const requestBody = {
      adv_id: advId,
      ...editedLanding,
      specials: formattedSpecials,

    };

    fetch(`${process.env.REACT_APP_API_BASE_URL}/adv/changeproperty`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(requestBody),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to update landing info');
        }
        return response.json();
      })
      .then(data => {
        console.log('Landing info updated successfully:', data);

        window.location.reload();
        if (onSave) onSave(data);
      })
      .catch(error => {
        console.error('Error updating landing info:', error);
      });
  };

  const handleAliasChange = (e) => {
    const updatedLanding = { ...editedLanding, alias: e.target.value };
    setEditedLanding(updatedLanding);
    setHasChanges(true);  // 设置为有修改
  };

  return (
    <div className="p-4 bg-white shadow-md rounded-lg mx-auto w-[95%] h-[83vh]">

      <div className="border-b border-gray-200">
        <nav className="hidden md:flex space-x-4">
          <button
            onClick={() => handleTabChange('Image Modification And Alias')}
            className={`px-4 py-2 ${activeTab === 'Image Modification And Alias' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'}`}
          >
            Main Image and Alia
          </button>
          <button
            onClick={() => handleTabChange('Specials')}
            className={`px-4 py-2 ${activeTab === 'Specials' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'}`}
          >
            Specials
          </button>
        </nav>
      </div>

      {/* small screen */}
      <div className="block md:hidden">
        <select
          value={activeTab}
          onChange={(e) => handleTabChange(e.target.value)}
          className="block w-full border-gray-300 rounded-md p-2"
        >
          <option value="Image Modification And Alias"> Main Image and Alia</option>
          <option value="Specials">Specials</option>

        </select>
      </div>

      {activeTab === 'Image Modification And Alias' && editedLanding && (
        <div>

          <EditAdvLanding
            landingInfo={editedLanding}
            onFileChange={handleFileChange}
            onSpecialChange={handleSpecialChange}
            onSpecialReorder={handleSpecialReorder}
            onSpecialAdd={handleAddSpecial}
            message={message}
            showMainImage={true}
            showSpecials={false}
          />

          <div class=" border-l border-r border-b border-gray-300 p-4 h-[30vh]">
            <h1 className='mt-2 font-bold'> Advertising Alia</h1>
            <div className='mt-8'>
              <InputWithCounter
                type="text"
                value={editedLanding.alias || ""}
                maxLength={64}
                onChange={handleAliasChange} />
            </div>
          </div>
        </div>
      )}

      {activeTab === 'Specials' && (
        <div className='h-[75vh] overflow-y-auto'>
          <EditAdvLanding
            landingInfo={editedLanding}
            onFileChange={handleFileChange}
            onSpecialChange={handleSpecialChange}
            onSpecialReorder={handleSpecialReorder}
            onSpecialAdd={handleAddSpecial}
            message={message}
            showMainImage={false}
            showSpecials={true}
          />
        </div>
      )}

      {/* Save Button */}
      <div className="text-right">

        <FloatingFooter hasChanges={hasChanges && canSave} onSave={openModal} />

        {/* Confirm Modal */}
        <ConfirmModal
          isOpen={isModalOpen}
          onClose={closeModal}
          onConfirm={confirmSave}
          header="Confirm Save"
          content="Are you sure you want to save the changes?" />


      </div>

    </div>

  );
};

export default ModifyAdvLanding;
