
export const transparentLogo = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
  
      reader.onload = (event) => {
        const img = new Image();
  
        img.onload = () => {
          const originalWidth = img.width;
          const originalHeight = img.height;
          const aspectRatio = originalWidth / originalHeight;
  
          // 1. 长宽比判断：必须在 [16/9, 25/9] 之间，否则 reject
          const minAspect = 8 / 9;  // ~1.7777
          const maxAspect = 25 / 9;  // ~2.7777
  
          if (aspectRatio < minAspect || aspectRatio > maxAspect) {
            return reject(new Error("Only 8:9-25:9 ratio images are accepted."));
          }
  
          // 2. 计算缩放比，让图片“contain”到 960×540 中
          const targetWidth = 960;
          const targetHeight = 540;
          const scale = Math.min(
            targetWidth / originalWidth,
            targetHeight / originalHeight
          );
  
          const scaledWidth = Math.round(originalWidth * scale);
          const scaledHeight = Math.round(originalHeight * scale);
  
          // 3. 把原图等比例缩放绘制到一个临时 canvas（用于后续做去背景）
          const scaledCanvas = document.createElement("canvas");
          scaledCanvas.width = scaledWidth;
          scaledCanvas.height = scaledHeight;
          const scaledCtx = scaledCanvas.getContext("2d");
          scaledCtx.drawImage(img, 0, 0, scaledWidth, scaledHeight);
  
          // 4. 在“缩放后”的图像上做去背景（背景角色 + 阈值）
          const imageData = scaledCtx.getImageData(0, 0, scaledWidth, scaledHeight);
          const data = imageData.data;
  
          // 获取指定(x, y)的 RGB
          const getColorAt = (x, y) => {
            const index = (y * scaledWidth + x) * 4;
            return [data[index], data[index + 1], data[index + 2]];
          };
  
          // 取四个角像素
          const topLeft = getColorAt(0, 0);
          const topRight = getColorAt(scaledWidth - 1, 0);
          const bottomLeft = getColorAt(0, scaledHeight - 1);
          const bottomRight = getColorAt(scaledWidth - 1, scaledHeight - 1);
  
          // 角落平均色当作背景色
          const backgroundColor = [
            (topLeft[0] + topRight[0] + bottomLeft[0] + bottomRight[0]) / 4,
            (topLeft[1] + topRight[1] + bottomLeft[1] + bottomRight[1]) / 4,
            (topLeft[2] + topRight[2] + bottomLeft[2] + bottomRight[2]) / 4,
          ];
  
          const threshold = 50; // 阈值，根据需要微调
  
          // 遍历像素：接近背景色 => 透明，否则 => 白色
          for (let i = 0; i < data.length; i += 4) {
            const r = data[i];
            const g = data[i + 1];
            const b = data[i + 2];
  
            if (
              Math.abs(r - backgroundColor[0]) < threshold &&
              Math.abs(g - backgroundColor[1]) < threshold &&
              Math.abs(b - backgroundColor[2]) < threshold
            ) {
              // 背景置为透明
              data[i + 3] = 0;
            } else {
              // 前景像素改为白色
              data[i] = 255;
              data[i + 1] = 255;
              data[i + 2] = 255;
            }
          }
  
          // 更新回去
          scaledCtx.putImageData(imageData, 0, 0);
  
          // 5. 创建最终 960×540 的画布，把缩放+去背景的图像“居中”绘制上去
          // 如果你希望最终文件就是“scaledWidth × scaledHeight”，就不用这一步，
          // 直接把 scaledCanvas.toBlob(...) 即可。
          const finalCanvas = document.createElement("canvas");
          finalCanvas.width = 960;  // 最终画布固定宽
          finalCanvas.height = 540; // 最终画布固定高
          const finalCtx = finalCanvas.getContext("2d");
  
          // 可选：如果要把剩余区域填充为白色，而不是透明，就这样写：
          // finalCtx.fillStyle = "#FFFFFF";
          // finalCtx.fillRect(0, 0, finalCanvas.width, finalCanvas.height);
  
          // 计算居中坐标，让缩放后的图像中心对齐 960×540 的中心
          const offsetX = (960 - scaledWidth) / 2;
          const offsetY = (540 - scaledHeight) / 2;
          finalCtx.drawImage(scaledCanvas, offsetX, offsetY);
  
          // 6. 通过 toBlob 输出最终文件（PNG）
          finalCanvas.toBlob((blob) => {
            if (blob) {
              const processedFile = new File([blob], file.name, {
                type: "image/png",
              });
              resolve(processedFile);
            } else {
              reject(new Error("Failed to create blob from canvas"));
            }
          }, "image/png");
        };
  
        img.onerror = () => {
          reject(new Error("图片加载失败，请检查文件是否正确"));
        };
  
        // 用 base64 方式读取文件
        img.src = event.target.result;
      };
  
      reader.onerror = (err) => {
        reject(err);
      };
  
      reader.readAsDataURL(file);
    });
  };