import React, { useContext, useState, useEffect } from 'react';
import { FaArrowUp, FaArrowDown, FaPlus, FaTrash, FaPencilAlt, FaLock, FaEye, FaEyeSlash, FaBars } from 'react-icons/fa';
import AppContext from '../contexts/AppContext';
import { useNavigate } from 'react-router-dom';
import ConfirmModal from '../components/ConfirmModal';
import SelectModal from '../components/SelectModal';


// Global object to store expanded node IDs
// TO DO 这里是没有超时时间的设置的，所以特别长时间使用可能会导致问题
const loadExpandedNodes = () => {
  try {
    const storedNodes = localStorage.getItem('expandedNodes');
    return storedNodes ? JSON.parse(storedNodes) : {};
  } catch {
    return {};
  }
};
const saveExpandedNodes = (nodes) => {
  localStorage.setItem('expandedNodes', JSON.stringify(nodes));
};
const expandedNodes = loadExpandedNodes();


export const RecursiveNode = ({ node, onMoveUp, onMoveDown, onDelete, onVisible, onAdd, path, userId, isRoot }) => {
  const [expand, setExpand] = useState(Boolean(expandedNodes[node.id]));
  useEffect(() => {
    // 当 expand 状态变化时，更新 localStorage
    saveExpandedNodes(expandedNodes);
  }, [expand]);
  const navigate = useNavigate();

  const handleEditClick = () => {
    navigate(`/detail/${node.id}`);
  };

  const handleAddClick = () => {
    onAdd(node.id)
  };

  const handleExpandToggle = () => {
    if (expand) {
      setExpand(false);
      delete expandedNodes[node.id];
    } else {
      setExpand(true);
      expandedNodes[node.id] = true;
    }
  };

  return (
    <div className="pt-2">
      <div className="flex flex-col lg:flex-row lg:items-center justify-between px-2 lg:px-6 py-2 lg:py-4 border-b border-gray-300">
        <div className={`w-full lg:w-3/5 flex items-center space-x-3`} style={{ paddingLeft: window.innerWidth >= 1024 ? `calc(${path.length} * 2rem)` : `calc(${path.length} * 1rem` }}>
          {!node.isLeaf ? (
            expand ? (
              <FaArrowDown className="h-5 w-5 text-blue-600 cursor-pointer" onClick={handleExpandToggle} />
            ) : (
              <FaArrowUp className="h-5 w-5 text-blue-600 cursor-pointer" onClick={handleExpandToggle} />
            )
          ) : (
            <FaBars className="h-5 w-5 text-gray-500" />
          )}
          <span onClick={() => node.isLeaf ? handleEditClick() : handleExpandToggle()} className='cursor-pointer'>{node.name}</span>
        </div>
        <div className="w-auto hidden lg:flex items-center justify-center ">
          {path.length !== 0 && (node.visible ? (
            <FaEye className="h-5 w-5 text-gray-500 cursor-pointer" onClick={() => onVisible(path)} />
          ) : (
            <FaEyeSlash className="h-5 w-5 text-gray-500 cursor-pointer" onClick={() => onVisible(path)} />
          ))}
        </div>
        {path.length !== 0 ?
          (node.editable === userId || isRoot) ?
            <div className="w-full lg:w-3/12 flex items-end space-x-3 justify-end lg:justify-center">
              <FaArrowUp className="h-5 w-5 text-gray-500 cursor-pointer" onClick={() => onMoveUp(path)} />
              <FaArrowDown className="h-5 w-5 text-gray-500 cursor-pointer" onClick={() => onMoveDown(path)} />
              {!node.isLeaf ? (
                <FaPlus className="h-5 w-5 text-gray-500 cursor-pointer" onClick={() => handleAddClick()} />
              ) : (
                <FaPlus className="h-5 w-5 text-white cursor-pointer" />
              )}
              <FaTrash className="h-5 w-5 text-red-500 cursor-pointer" onClick={() => onDelete(path)} />
              <FaPencilAlt className="h-5 w-5 text-blue-600 cursor-pointer" onClick={() => handleEditClick()} />
            </div>
            :
            <div className="w-full lg:w-3/12 flex items-end justify-end lg:justify-center">
              <FaLock className="h-5 w-5 text-gray-500" />
            </div>
          :
          (node.editable === userId || isRoot) ?
            <div className="w-full lg:w-3/12 flex items-end justify-end lg:justify-center space-x-3">
              <FaPlus className="h-5 w-5 text-gray-500 cursor-pointer" onClick={() => handleAddClick()} />
              <FaPencilAlt className="h-5 w-5 text-blue-600 cursor-pointer" onClick={() => handleEditClick()} />
            </div>
            :
            <div className="w-full lg:w-3/12 flex items-end justify-end lg:justify-center">
              <FaLock className="h-5 w-5 text-gray-500" />
            </div>
        }
      </div>
      {expand && node.attributes && node.attributes.length > 0 && (
        <div className="">
          {node.attributes.map((child, index) => (
            <RecursiveNode
              key={child.id}
              node={child}
              path={[...path, index]}
              onMoveUp={onMoveUp}
              onMoveDown={onMoveDown}
              onDelete={onDelete}
              onVisible={onVisible}
              onAdd={onAdd}
              userId={userId}
              isRoot={isRoot}
            />
          ))}
        </div>
      )}
    </div>
  );
};


const Content = () => {
  const { content, setContent, token, userInfo, } = useContext(AppContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isContentAddOpen, setIsContentAddOpen] = useState(false);
  const [deletePath, setDeletePath] = useState(null);
  const [parentNodeId, setParentNodeId] = useState(null);
  const navigate = useNavigate();

  const findNode = (path, node) => {
    if (path.length === 0) return node;
    const [first, ...rest] = path;
    return findNode(rest, node.attributes[first]);
  };

  const handleMoveUp = async (path) => {
    const newContent = { ...content };
    const parentPath = path.slice(0, -1);
    const index = path[path.length - 1];
    const parentNode = findNode(parentPath, newContent);

    if (index > 0) {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/contentupdate/swap`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          email: userInfo.user.email,
          content_parent_id: parentNode.id,
          content_former_id: parentNode.attributes[index - 1].id,
          content_latter_id: parentNode.attributes[index].id,
        }),
      });
      if (response.ok) {
        [parentNode.attributes[index - 1], parentNode.attributes[index]] = [
          parentNode.attributes[index],
          parentNode.attributes[index - 1],
        ];
        setContent(newContent);
      }
    }
  };

  const handleMoveDown = async (path) => {
    const newContent = { ...content };
    const parentPath = path.slice(0, -1);
    const index = path[path.length - 1];
    const parentNode = findNode(parentPath, newContent);

    if (index < parentNode.attributes.length - 1) {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/contentupdate/swap`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          email: userInfo.user.email,
          content_parent_id: parentNode.id,
          content_former_id: parentNode.attributes[index].id,
          content_latter_id: parentNode.attributes[index + 1].id,
        }),
      });
      if (response.ok) {
        [parentNode.attributes[index], parentNode.attributes[index + 1]] = [
          parentNode.attributes[index + 1],
          parentNode.attributes[index],
        ];
        setContent(newContent);
      }
    }
  };

  const handleDelete = (path) => {
    setDeletePath(path);
    setIsModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    const newContent = { ...content };
    const parentPath = deletePath.slice(0, -1);
    const index = deletePath[deletePath.length - 1];
    const parentNode = findNode(parentPath, newContent);
    const deleteNode = parentNode.attributes[index];
    parentNode.attributes.splice(index, 1);
    setContent(newContent);
    setIsModalOpen(false);
    await fetch(`${process.env.REACT_APP_API_BASE_URL}/contentupdate/delete`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        email: userInfo.user.email,
        content_id: deleteNode.id,
        content_parent_id: parentNode.id,
      }),
    });
  };

  const handleVisibleChange = async (path) => {
    const newContent = { ...content };
    const node = findNode(path, newContent);
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/contentupdate/visibility`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        email: userInfo.user.email,
        content_id: node.id,
        visible: !node.visible,
      }),
    });
    if (response.ok) {
      node.visible = !node.visible;
      setContent(newContent);
    }
  };

  const handleContentAdd = (nodeId) => {
    setIsContentAddOpen(true)
    setParentNodeId(nodeId);
  }

  return (
    <div className="w-full max-w-7xl mx-auto px-2 lg:pl-64 pl-0 pt-20  pb-16">
      <div className="text-3xl text-blue-600 font-semibold py-4 hidden md:block">My Concierge Content</div>
      <div className="bg-white shadow-md rounded-lg">
        <div className="flex items-center justify-between px-6 py-4 border-b border-gray-200">
          <div className="w-3/5 font-semibold">Title</div>
          <div className="w-auto hidden lg:block font-semibold text-center">Visible</div>
          <div className="lg:w-3/12 w-2/5 font-semibold text-center">Actions</div>
        </div>
        {content &&
          <RecursiveNode
            key={content.id}
            node={content}
            path={[]}
            onMoveUp={handleMoveUp}
            onMoveDown={handleMoveDown}
            onDelete={handleDelete}
            onVisible={handleVisibleChange}
            onAdd={handleContentAdd}
            userId={userInfo.user.id}
            isRoot={userInfo.user.permissionLevel === 9}
          />
        }
      </div>
      <ConfirmModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleConfirmDelete}
        header="Confirm Delete"
        content="Are you sure you want to delete this item?"
      />
      <SelectModal
        isOpen={isContentAddOpen}
        onClose={() => setIsContentAddOpen(false)}
        onLeftClick={() => { setIsContentAddOpen(false); navigate(`/detail/${parentNodeId}/add/list`); }}
        onRightClick={() => { setIsContentAddOpen(false); navigate(`/detail/${parentNodeId}/add/entry`); }}
        header="Select the type of Content"
        content="Choose a directory list to hold entries or a directory entry as a standalone item."
        leftText="Add directory list"
        rightText="Add directory entry"

      />
    </div>
  );

};

export default Content;