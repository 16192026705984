import React, { useRef, useState } from "react";
import { QRCodeCanvas } from "qrcode.react";

const QRCodeGenerator = ({ selectedRole }) => {
  const qrRef = useRef();
  const [copySuccess, setCopySuccess] = useState(false);

  // 动态生成 URL
  const baseURL = "https://venue.pladdypus.com/?spot_id=";
  const dynamicURL = `${baseURL}${selectedRole.id || ""}`;

  const downloadQRCode = () => {
    const canvas = qrRef.current.querySelector("canvas");
    const imageURL = canvas.toDataURL("image/png");
    const link = document.createElement("a");
    link.href = imageURL;
    link.download = `QRCode_${selectedRole.id}.png`;
    link.click();
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(dynamicURL).then(() => {
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 1500);
    });
  };

  return (
    <div className="h-[70vh] flex items-center justify-center">
      <div className="p-6 grid grid-cols-1 md:grid-cols-2 gap-6 items-center">
        {/* 左侧 QR Code 区域 */}
        <div className="flex flex-col items-center space-y-4">
          <div ref={qrRef} className="p-4 bg-white border rounded">
            <QRCodeCanvas
              value={dynamicURL}
              size={200}
              bgColor="#ffffff"
              fgColor="#000000"
              includeMargin={true}
            />
          </div>
          <button
            onClick={downloadQRCode}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          >
            Download QR Code
          </button>
        </div>

        {/* 右侧链接显示和复制区域 */}
        <div className="flex flex-col items-center space-y-4">
          <div className="p-4 bg-white border rounded shadow w-full">
            <p className="text-gray-600">
              <strong>Website URL:</strong>
              <br />
              <span
                className="text-blue-500 block truncate"
                title={dynamicURL}
              >
                {dynamicURL}
              </span>
            </p>
          </div>
          <div className="flex justify-center w-full">
            <button
              onClick={copyToClipboard}
              className={`px-10 py-2 rounded ${copySuccess ? "bg-green-500 text-white" : "bg-blue-500 text-white"} hover:bg-blue-600`}
            >
              {copySuccess ? "Copied" : "Copy Link"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QRCodeGenerator;