import React, { createContext, useState, useEffect, useCallback } from 'react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userInfo, setUserInfo] = useState(null);
  const [content, setContent] = useState(null);
  const [isMasterUser, setIsMasterUser] = useState(Cookies.get('isMasterUser') || false);//Only to see if this is a follower user
  const navigate = useNavigate();

  // this is used for high level spot/adv change
  const savedRole = sessionStorage.getItem('selectedRole');
  const initialSelectedRole = savedRole
    ? JSON.parse(savedRole)
    : { id: null, type: null }; // 默认值
  const [selectedRole, setSelectedRole] = useState(initialSelectedRole);

  const fetchUserInfo = useCallback(async (token) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/usersupport/info`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setUserInfo(data);
        return data;
      } else {
        Cookies.remove('token');
        setToken(null);
        navigate("/login");
      }
    } catch (error) {
      Cookies.remove('token');
      setToken(null);
      navigate("/login");
    }
  }, [navigate]);

  //endpoint for fetching spot content
  const fetchAllInfo = useCallback(async (token) => {
    try {
      const data = await fetchUserInfo(token);
      Cookies.set('token', token, { expires: 1, secure: false, sameSite: 'Strict' });
      if (data.user.permissionLevel === 9) {
        setSelectedRole({ id: null, type: 'root' })
        return;
      }

      if (data.roleInfoIdCollection) {
        if (data.roleInfoIdCollection.spot && data.roleInfoIdCollection.spot.length > 0) {
          if (!selectedRole.type) setSelectedRole({ id: data.roleInfoIdCollection.spot[0], type: 'spot' });
          // await fetchSpotContent(token, data.user.email, data.roleInfoIdCollection.spot[0]);

        } else if (data.roleInfoIdCollection.adv && data.roleInfoIdCollection.adv.length > 0) {
          if (!selectedRole.type) setSelectedRole({ id: data.roleInfoIdCollection.adv[0], type: 'adv' });
          // await fetchAdvContent(token, data.user.email, data.roleInfoIdCollection.adv[0]);
        }
      }

    } catch (error) {
      console.error('Error fetching all info:', error);
      navigate("/login");
    }
  }, [navigate, fetchUserInfo, selectedRole.type ]);

  const fetchRootContent = useCallback(async (token, email) => {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/spot/content/root`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({
        email,
      }),
    });
    if (response.ok) {
      const data = await response.json();
      setContent(data);
      Cookies.set('token', token, { expires: 1, secure: false, sameSite: 'Strict' });
    }
  }, [])

  //endpoint for fetching spot content
  const fetchSpotContent = useCallback(async (token, email, spotId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/spot/content/spotwhole`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          email,
          spot_id: spotId
        }),
      });

      if (response.ok) {
        const data = await response.json();
        setContent(data);
        Cookies.set('token', token, { expires: 1, secure: false, sameSite: 'Strict' });
      }
    } catch (error) {
      console.error('Error fetching content:', error);
      // Handle the error here
      navigate("/login")
    }
  }, [navigate]);

  //endpoint for fetching adv content
  const fetchAdvContent = useCallback(async (token, email, advId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/adv/content/advwhole`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          email,
          adv_id: advId
        }),
      });
      if (response.ok) {
        const data = await response.json();
        setContent(data);
      }
    } catch (error) {
      console.error('Error fetching content:', error);
      // Handle the error here
      navigate("/login")
    }
  }, [navigate]);

  const uploadImage = async (image, width, height) => {
    try {
      // 检查文件大小是否超过 5MB
      const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
      if (image.size > MAX_FILE_SIZE) {
        alert('File size exceeds 5MB');
        return;
        // return Promise.reject('File size exceeds 5MB'); // 返回错误
      }
  
      const formData = new FormData();
      formData.append('image', image);
      formData.append('width', width);
      formData.append('height', height);
  
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/usersupport/uploadimage`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        body: formData
      });
  
      if (response.ok) {
        const data = await response.json();
        return data.fileUrl;
      } else {
        console.error('Failed to fetch user info');
        navigate("/login");
      }
    } catch (error) {
      console.error('Error fetching user info:', error);
      // navigate("/login");
    }
  };
  

  useEffect(() => {

    const token = Cookies.get('token');
    if (token) {
      setToken(token);
      fetchAllInfo(token)
    } else {
      Cookies.remove('token');
      setToken(null);
    }
    setLoading(false);
  }, [fetchAllInfo, selectedRole.type]);

  // get corresponding content from currently selected role
  useEffect(() => {
    if (selectedRole.type === 'root' && userInfo) {
      fetchRootContent(token, userInfo.user.email);
    } else if (selectedRole.id && userInfo) {
      if (selectedRole.type === 'spot') {
        fetchSpotContent(token, userInfo.user.email, selectedRole.id);
      }
      if (selectedRole.type === 'adv') {
        fetchAdvContent(token, userInfo.user.email, selectedRole.id);
      }
    }
    sessionStorage.setItem('selectedRole', JSON.stringify(selectedRole));

  }, [selectedRole, fetchAdvContent, fetchSpotContent, fetchRootContent, token, userInfo]);

  return (
    <AppContext.Provider value={{
      token, setToken, loading, fetchAllInfo, selectedRole, setSelectedRole,
      userInfo, content, setContent, uploadImage,
      isMasterUser, setIsMasterUser
    }}>

      {children}
    </AppContext.Provider>
  );
};

export default AppContext;
