import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setErrorMessage('Passwords do not match');
      setSuccessMessage('')
      return;
    }

    const token = searchParams.get('token');
    const email = searchParams.get('email');

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/usersupport/resetpwd`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token,
          email,
          newPassword,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to reset password');
      }
      setErrorMessage('');
      setSuccessMessage('Password reset successfully. Redirecting to login...');
      setTimeout(() => {
        navigate('/login');
      }, 3000);
    } catch (error) {
      setErrorMessage('Error resetting password. Please try again.');
    }
  };

  return (
    <div className="w-full h-screen flex items-center justify-center bg-gray-100">
      <video autoPlay muted loop className="absolute inset-0 w-full h-full object-cover z-0">
        <source src={`${process.env.PUBLIC_URL}/assets/background.mp4`} type="video/mp4" />
      </video>
      <div className="relative z-10 w-full max-w-xs md:max-w-xl ">
        <div className='bg-white px-0 pt-0 pb-2 md:px-6 md:pt-2 md:pb-2'>

          <div className="bg-blue-900 rounded-t-lg flex justify-center">
            <img src={`${process.env.PUBLIC_URL}/assets/Pladdypus_Long_Banner.jpg`} className="w-full" alt="Logo" />
          </div>

          <div className="px-4 md:px-6 text-gray-500">
            <form onSubmit={handleSubmit}>
              <div className="mb-4">

                <input
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  className="w-full px-3 py-2 border rounded text-black"
                  required
                />
                <label className="block mb-1 mt-1">New Password</label>
              </div>
              <div className="mb-4">

                <input
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="w-full px-3 py-2 border rounded text-black"
                  required
                />
                <label className="block mb-1 mt-1">Confirm Password</label>
              </div>
              <button
                type="submit"
                className="w-full bg-blue-600 text-white py-2 rounded hover:bg-blue-800 transition duration-200 font-medium"
              >
                Reset Password
              </button>
            </form>
          </div>
        </div>
        {errorMessage &&
          <div className=" bg-red-100 text-red-700 px-4 py-2 md:px-12 md:pt-2 md:pb-4">
            <h3 className="text-lg font-semibold">Action Required</h3>
            <p>Password Mismatch</p>
          </div>
        }
        {successMessage &&
          <div className="bg-green-100 text-green-500 px-4 py-2 md:px-12 md:pt-2 md:pb-4">
            <p>{successMessage}</p>
          </div>
        }
      </div>
    </div>
  );
};

export default ResetPassword;
