import React from 'react';
import { XMarkIcon } from '@heroicons/react/24/solid';

const InformModal = ({ isOpen, onClose, content, type = "inform" }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-10 lg:pl-64 pl-0">
      <div className="fixed inset-0 bg-black opacity-50" onClick={onClose}></div>
      
      <div className="bg-white p-8 rounded-lg shadow-2xl max-w-md w-full z-50 relative text-center border border-gray-200">
        
        <button onClick={onClose} className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-300 rounded-full">
          <XMarkIcon className="h-7 w-7" />
        </button>

        {type === "inform" ? (
          <p className="text-gray-700 text-lg">{content}</p>
        ) : (
          <p className="text-red-500 text-lg">{content}</p>
        )}
      </div>
    </div>
  );
};

export default InformModal;
