import React from 'react';

// FloatingFooter component
const FloatingFooter = ({ hasChanges, onSave, saveLabel = "Save" }) => {
  // Internal handleUndo function to refresh the page
  const handleUndo = () => {
    window.location.reload(); // Refresh page
  };

  return (
    <div 
      className="fixed bottom-0 left-0 w-full bg-white shadow-lg py-2 flex justify-center md:justify-center"
    >
      <div className="w-full max-w-screen-lg flex justify-end space-x-4"> 
        {/* Use space-x-4 to add horizontal space between buttons */}
        <button
          type="button"
          onClick={handleUndo} // Call handleUndo on click
          className={`w-36 px-6 py-2 mr-4 font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 whitespace-nowrap ${
            hasChanges
              ? 'bg-blue-500 text-white hover:bg-blue-600 focus:ring-blue-500'
              : 'bg-gray-300 text-gray-500 cursor-not-allowed'
          }`}
          disabled={!hasChanges}
        >
          Cancel
        </button>
        <button
          type="button"
          onClick={onSave} // Call onSave passed from the parent component
          className={`w-36 px-6 py-2 font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 whitespace-nowrap ${
            hasChanges
              ? 'bg-blue-500 text-white hover:bg-blue-600 focus:ring-blue-500'
              : 'bg-gray-300 text-gray-500 cursor-not-allowed'
          }`}
          disabled={!hasChanges}
        >
          {saveLabel} {/* Display the label dynamically */}
        </button>
      </div>
    </div>
  );
};

export default FloatingFooter;
