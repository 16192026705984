const ConfirmModal = ({
  isOpen,
  onClose,
  onConfirm,
  header,
  content,
  cancelText = "Cancel",
  confirmText = "Confirm", 
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-1000 lg:pl-64 pl-0">
      <div className="fixed inset-0 bg-black opacity-40 z-1000"></div>
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full z-1000 relative text-center">
        <p className="text-2xl font-semibold mb-4 text-gray-800">{header}</p>
        <p className="text-gray-600 mb-6">{content}</p>

        <div className="flex justify-center space-x-20">
          <button
            className="px-4 py-2 rounded-md bg-gray-200 hover:bg-gray-300 text-gray-700 transition duration-200"
            onClick={onClose}
          >
            {cancelText}
          </button>
          <button
            className="px-4 py-2 rounded-md bg-red-500 text-white hover:bg-red-600 transition duration-200"
            onClick={onConfirm}
          >
            {confirmText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
