import React from 'react';
import ArticleUpdateForm from '../components/ArticleUpdateForm';

const ArticleDetail = () => {
  return (

      <div className='pl-0 lg:pl-64 pt-20'>
      <div className="p-4 bg-white shadow-md rounded-lg max-w-[1500px] mx-auto mt-5 min-h-[650px]">

        <ArticleUpdateForm></ArticleUpdateForm>

      </div>

    </div>


  );
};

export default ArticleDetail;