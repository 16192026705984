import React, { useState, useEffect, useRef } from 'react';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';

import { useNavigate } from 'react-router-dom';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [captchaText, setCaptchaText] = useState('');
  const [captchaToken, setCaptchaToken] = useState('');
  const [captchaImage, setCaptchaImage] = useState('');
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const navigate = useNavigate();

  const debounceTimeout = useRef(null);


  useEffect(() => {
    fetchCaptcha();
  }, []);

  const fetchCaptcha = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/usersupport/captcha`);
      const data = await response.json();
      setCaptchaToken(data.captchaToken);
      setCaptchaImage(data.captchaImage);
    } catch (error) {
      console.error('Error fetching captcha:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/usersupport/forgetpwd`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          captchaText,
          captchaToken,
        }),
      });

      if (!response.ok) {
        throw new Error('Request failed');
      }

      setShowSuccess(true);
      setShowError(false);
      setErrorMessage('');
      setEmail('');
      setCaptchaText('');
      fetchCaptcha(); // Refresh captcha after successful request
    } catch (error) {
      console.error('Request error:', error);
      setShowError(true);
      setShowSuccess(false);
      setErrorMessage('Failed to submit request. Please try again.');
      fetchCaptcha(); // Refresh captcha on error
    }
  };

  const handleCaptchaClick = () => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    debounceTimeout.current = setTimeout(() => {
      fetchCaptcha();
    }, 1000);
  };

  useEffect(() => {
    if (email !== '' || captchaText !== '') setShowError(false);
  }, [email, captchaText]);


  return (
    <div className="w-full h-screen flex items-center justify-center bg-gray-100 rounded-lg">
      <video autoPlay muted loop className="absolute inset-0 w-full h-full object-cover z-0">
        <source src={`${process.env.PUBLIC_URL}/assets/background.mp4`} type="video/mp4" />
      </video>

      <div className="relative z-10 w-full max-w-xs md:max-w-xl ">
        <div className=' bg-white px-0 pt-0 pb-2 md:px-6 md:pt-2 md:pb-4'>
          <div className="flex justify-center">
            <img src={`${process.env.PUBLIC_URL}/assets/Pladdypus_Long_Banner.jpg`} className="w-full rounded-lg" alt="Logo" />
          </div>

          <div className="px-6 text-gray-700">

            <div className="flex items-center mb-4">
              <ArrowLeftIcon className="h-6 w-6 text-black cursor-pointer hover:color-blue-600 transition duration-200" onClick={() => { navigate(-1) }} />
              <h2 className="text-lg font-semibold ml-2 text-blue-600">Password Forgot</h2>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full px-3 py-2 border rounded"
                  required
                />
                <label className="block mb-1 mt-1">Email</label>
              </div>
              <div className="mb-4">
                <div className="flex">
                  <div className="w-1/2 pr-2">
                    <img src={captchaImage} alt="Captcha" onClick={handleCaptchaClick} className="cursor-pointer" />
                  </div>
                  <div className="w-1/2 pl-2">
                    <input
                      type="text"
                      value={captchaText}
                      onChange={(e) => setCaptchaText(e.target.value)}
                      className="w-full px-3 py-2 border rounded"
                      required
                    />
                  </div>
                </div>
              </div>

              <button
                type="submit"
                className="w-full bg-blue-600 text-white py-2 rounded hover:bg-blue-800 transition duration-200"
              >
                Submit
              </button>
            </form>
          </div>
        </div>

        {showError && (
          <div className="px-4 py-2 bg-red-100 text-red-700 rounded-b-lg md:px-12 md:pt-2 md:pb-4">
            <h3 className="text-lg font-semibold">Action Required</h3>
            <p>{errorMessage}</p>
          </div>
        )}

        {showSuccess && (
          <div className="px-4 py-2 bg-green-100 text-green-700 rounded-b-lg md:px-12 md:pt-2 md:pb-4">
            <h3 className="text-lg font-semibold">Success</h3>
            <p>Password reset instructions have been sent to your email.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;
